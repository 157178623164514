import styled from 'styled-components';

export const StyledButton = styled.button<{disabled?: boolean}>`
  border-radius: 8px;
  border: none;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  padding: 20px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.persianBlue};
  &.primary {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.persianBlue};
    border-radius: 31px;
    box-shadow: 0px 10px 10px ${({theme}) => theme.colors.shadowBlack};
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    letter-spacing: 0.05px;
    padding 5px 10px;
  }
  &.secondary {
    color: ${({ theme }) => theme.colors.persianBlue};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 31px;
    box-shadow: 0px 10px 10px ${({theme}) => theme.colors.shadowBlack};;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: bold;
    letter-spacing: 0.05px;
    padding: 0;
  }
  &.transparent {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.transparent};
    height: auto;
    text-align: center;
    text-decoration: underline;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.62px;
    padding: 0;
  }
  &.disabled {
    background: ${({ theme }) => theme.colors.silver} 0% 0% no-repeat padding-box;
  }
`;
