import { SORT_DIRECTION } from '@common/types';

const namespace = 'AFFILIATE_CODES_';

export default {
  GET_AFFILIATE_CODE_BATCHES: `${namespace}GET_AFFILIATE_CODE_BATCHES`,
  SET_AFFILIATE_CODE_BATCHES: `${namespace}SET_AFFILIATE_CODE_BATCHES`,
  GET_CODE_BATCH_DETAIL: `${namespace}GET_CODE_BATCH_DETAIL`,
  SET_CODE_BATCH_DETAIL: `${namespace}SET_CODE_BATCH_DETAIL`,
  SET_CODE_STATUS: `${namespace}SET_CODE_STATUS`,
  GET_AFFILIATE_CODES: `${namespace}GET_AFFILIATE_CODES`,
  SET_AFFILIATE_CODES: `${namespace}SET_AFFILIATE_CODES`
};

export const PAGE_LIST_INIT_STATE = {
  page_size: 0,
  page_number: 0,
  total_pages: 0,
  next_page: 0,
  sort_key: '',
  sort_direction: SORT_DIRECTION.ASC,
  sort_options: {
    sort_keys: [],
    sort_directions: []
  },
  page: []
};

