import styled from 'styled-components';
import { colors } from '@theme';
import { Button } from '@components/atoms';

export const StyledAccountDetailContainer = styled.section`
  min-height: 70vh;
  width: 100%;
  padding: 24px 0 50px 0;
  .page-heading {
    width: 90%;
    text-align: left;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: 0.14px;
    color: ${colors.persianBlue};
    margin: auto;
  }
`;

export const StyledBodyContainer = styled.div<{ isFullWidth: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 26px 0 0 0;
  box-sizing: border-box;
  .react-tabs__tab-list {
    border-bottom: 1px solid ${colors.persianBlue};
    margin: 0;
    .custom-tab-class {
      width: 166px;
      height: 39px;
      border: 1px solid ${colors.persianBlue};
      border-radius: 4px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.67px;
      color: ${colors.persianBlue};
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .selected-tab {
      width: 166px;
      height: 39px;
      background-color: ${colors.persianBlue};
      border-radius: 4px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.67px;
      color: ${colors.white};
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .react-tabs__tab {
      padding: 0;
    }
  }
  .react-tabs {
    min-width: ${({ isFullWidth }) => isFullWidth ? '90%' : 'auto'};
    max-width: 90%;
  }
`;

export const StyledFormWrapper = styled.section`
  padding: 26px 0 26px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 900px;
  position: relative;
  .fragment-wrapper {
    width: 100%;
  }
  input {
    width: 100%;
    height: 37px;
    border: 1px solid ${colors.lunarRock};
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.67px;
    color: ${colors.cloudyGrey};
  }
  label {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.67px;
    color: ${colors.cloudyGrey};
  }
  select {
    border: 1px solid ${colors.lunarRock};
    border-radius: 5px;
  }
  .username-input-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 19px;
    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      column-gap: 0px;
    }
  }

  .bm-input-wrapper {
    width: 100%;
  }
  .login-header-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px;
  }

  .save-button {
    width: 173px;
    height: 45px;
    margin-top: 45px;
  }
  .password-wrapper {
    width: 100%;
    box-sizing: border-box;
    .bm-input-wrapper {
      width: calc(50% - 9.5px);
    }
  }
  @media only screen and (max-width: 1000px) {
    min-width: auto;
    max-width: 100%;
  }
`;

export const StyledEditButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledEditButton = styled(Button)`
  width: 107px;
  height: 39px;
  border: 1px solid ${colors.persianBlue};
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: bm-regular;
    font-weight: normal;
    margin-right: 11px;
  }
  img {
    width: 21px;
    height: 21px;
    object-fit: contain;
  }
`;

export const StyledTableWrapper = styled.div`
  padding: 26px 0 26px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 900px;
  width: 900px; */
  position: relative;
  max-width: 100%;
  & > section {
    max-width: 100% !important;
  }
`;

export const StyledAddUserButton = styled(Button)`
  width: 108px;
  height: 39px;
  border: 1px solid ${colors.persianBlue};
  border-radius: 31px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.05px;
  color: ${colors.persianBlue};
`;

export const StyledAddNewUserWrapper = styled.div`
  padding: 26px 0 26px 0;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > section {
    max-width: 100% !important;
  }
  @media only screen and (max-width: 1000px) {
    max-width: 100%;
    & > section {
      max-width: 90% !important;
    }
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .bm-select-wrapper {
    width: 60%;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;

export const StyledLabel = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.67px;
  color: ${colors.cloudyGrey};
  margin-bottom: 15px;
`;
