import axios, { AxiosError, AxiosResponse } from 'axios';
import { IApiCallerConfig, IApiErrorResponse } from './apiCaller';
import axiosInterceptorConfig from './getAxiosRequestConfig';
import { commonActions } from './actions';
import store from '@store/index';
import { toast } from 'react-toastify';
import history from '@src/common/history';
import { ROUTE_METADATA } from '@common/constants/routeConstants';
import { LOCAL_STORAGE, removeLocalStorage } from '@common/utils/localStorageUtils';

import { downloadCSV } from './utils';

axios.interceptors.request.use(
  (config: IApiCallerConfig = {}): IApiCallerConfig => axiosInterceptorConfig(config),
  (error: AxiosError): Promise<object> => Promise.reject(error)
);

/* eslint-disable */
axios.interceptors.response.use(
  (response): AxiosResponse => {
    if(response.headers?.['content-type'] ==='text/csv' && response.data) {
      downloadCSV(response.data);
    }
    store.dispatch(commonActions.removeLoader());
    return response.data;
  },
  async (error: AxiosError): Promise<IApiCallerConfig> => {
    const errorConfig = error.config as IApiCallerConfig;
    const errorData = error.response?.data as IApiErrorResponse;
    if (errorConfig?.showGenericError) {
      if (!errorData?.success) {
        toast(errorData?.payload?.message || error.message, {
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
      }
    }
    store.dispatch(commonActions.removeLoader());
    if(error.response?.status === 401) {
      removeLocalStorage(LOCAL_STORAGE.USER_DETAIL);
      history.push(ROUTE_METADATA.LOGIN.path);
    }
    return Promise.reject(error.response || 'An unknown error has occurred!');
  },
);
