import axios, { AxiosRequestConfig } from 'axios';
import '@store/axiosInterceptors';

export interface IAxiosInstance {
  get<R>(path: string, config?: IApiCallerConfig): Promise<R>;
  post<R>(path: string, payload?: object, config?: IApiCallerConfig): Promise<R>;
  put<R>(path: string, payload: object, config?: IApiCallerConfig): Promise<R>;
  delete<R>(path: string, payload: object, config?: IApiCallerConfig): Promise<R>;
  patch<R>(path: string, payload: object, config?: IApiCallerConfig): Promise<R>;
}

export interface IApiCallerConfig extends AxiosRequestConfig {
  withBaseUrl?: boolean;
  includeHeaders?: boolean;
  skipHeaders?: boolean;
  loader?: boolean;
  showGenericError?: boolean;
}

export interface IApiErrorResponse {
  success: boolean;
  payload: {
    error: string;
    message: string;
    status: number;
  };
}

const apiCaller = {
  get: <T>(path: string, config?: IApiCallerConfig) => (axios as IAxiosInstance).get<T>(path, config),
  post: <T>(path: string, payload: object, config?: IApiCallerConfig) =>
    (axios as IAxiosInstance).post<T>(path, payload, config),
  put: <T>(path: string, payload: object, config?: IApiCallerConfig) =>
    (axios as IAxiosInstance).put<T>(path, payload, config),
  delete: <T>(path: string, config?: IApiCallerConfig) => (axios as IAxiosInstance).delete<T>(path, config || {})
};

export default apiCaller;
