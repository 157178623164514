import React from 'react';
import { ThemeProvider } from 'styled-components';
import { colors } from './index';

export { colors };

export type IColors = typeof colors;

export interface IFontSizes {
  [key: string]: string;
}

export interface ITheme {
  colors: IColors;
  fonts: string[];
  fontSizes: IFontSizes;
}

const theme: ITheme = {
  colors: { ...colors },
  fonts: [],
  fontSizes: {
    xsm: '0.75rem',
    sm: '1em',
    md: '1.5rem',
    lg: '2rem',
    xl: '2.75rem'
  }
};

export interface IProps {
  children: React.ReactNode;
}

const Theme = (props: IProps) => {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
