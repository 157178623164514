import { useEffect, useState } from 'react';
import { StyledAccountDetailContainer, StyledAddNewUserWrapper, StyledFlex, StyledFormWrapper, StyledLabel } from './styles';
import { Button, SelectDropDown, TextInput } from '@components/atoms';
import { Header } from '@src/components/molecules';
import { IErrorInputFields, IFormFieldProp, IVALIDATION_ACTION, IValidationProps } from '@common/types';
import { validateSignup } from '@common/utils/formValidation';
import { IFormData } from '@src/pages/authentication/signup/types';

import affiliateAccountActions from './store/actions';
import { getUserRolesSelector } from './store/selector';
import { ADD_NEW_USER } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLE_TYPES } from '@common/utils/appConfig';
import { INPUT_TYPE } from '@common/enums';

export interface IFormState {
  email: string;
  role: USER_ROLE_TYPES | string;
}

const AddNewUser = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IFormState>({ email: '', role: '' });
  const [errorFields, setErrorFields] = useState<IErrorInputFields>();
  const userRoles = useSelector(getUserRolesSelector);
  const [selectedUserRole, setSelectedUserRole] = useState<{label: string; value: string}>();
  const selectedAffiliateData = history?.state?.usr;

  useEffect(() => {
    if(selectedAffiliateData) {
      setFormData({ email: selectedAffiliateData.email, role: selectedAffiliateData.role });
      setSelectedUserRole({ value: selectedAffiliateData.role, label: selectedAffiliateData.role });
    }
    dispatch(affiliateAccountActions.getUserRoles());
  }, []);

  useEffect(() => {
    if(userRoles && userRoles.length && !selectedAffiliateData) {
      setSelectedUserRole({ label: userRoles[0], value: userRoles[0] });
      setFormData({ ...formData, role: userRoles[0] });
    }
  }, [userRoles]);

  const handleFormValidation = (
    value: string | number,
    key: string,
    action: IVALIDATION_ACTION,
    validation?: IValidationProps
  ) => {
    const { errorFields: errorFieldInResponse } = validateSignup({
      value,
      action,
      key,
      errorFields,
      validation
    });

    if (errorFieldInResponse) {
      setErrorFields({ ...errorFieldInResponse });
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.id;
    const value = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleBlurInput = (event: React.ChangeEvent<HTMLInputElement>, validation?: IValidationProps) => {
    const name = event.target.id;
    const value = event.target.value;
    handleFormValidation(value, name, 'onBlur', validation);
  };

  const handleUpdate = () => {
    const { isError, errorFields: errorFieldsResponse } = validateSignup({
      formData: formData as unknown as IFormData,
      action: 'onSubmit',
      errorFields,
      formFields: ADD_NEW_USER
    });

    if (isError && errorFieldsResponse) {
      setErrorFields({ ...errorFieldsResponse });
    }

    if (!isError) {
      if (selectedAffiliateData) {
        const updateUserPayload = {
          user_id: selectedAffiliateData.user_id,
          role: [formData.role] as USER_ROLE_TYPES[]
        };
        dispatch(affiliateAccountActions.updateUser(updateUserPayload));
      } else {
        const addUserPayload = {
          email: formData.email,
          role: formData.role as USER_ROLE_TYPES
        };
        dispatch(affiliateAccountActions.addNewUser(addUserPayload));
      }
    } else {
      if (errorFieldsResponse) {
        setErrorFields({ ...errorFieldsResponse });
      }
    }
  };

  const getRoleOptions = () => {
    const userRolesData = Array.isArray(userRoles) && userRoles.map(item => ({value: item, label: item }));

    return userRolesData || [];
  };

  const handleSelectDropdown = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
    setSelectedUserRole({ label: value, value });
  };

  const getInputValue = (key: string) => formData[key] || '';

  return (
    <>
      <Header rightOptions={[]} />
      <StyledAccountDetailContainer>
        <div className="page-heading">{selectedAffiliateData ? 'Update' : 'Add'} a team member</div>
        <StyledAddNewUserWrapper>
          <StyledFormWrapper>
            {ADD_NEW_USER.map((item: IFormFieldProp[] | IFormFieldProp, index) => {
              if (Array.isArray(item)) {
                return null;
              } else {
                if(item.type === INPUT_TYPE.SELECT) {
                  return (
                    <StyledFlex key={index}>
                      <StyledLabel>{item.label}</StyledLabel>
                      <SelectDropDown
                        onSelect={handleSelectDropdown}
                        name={item.name}
                        options={getRoleOptions()}
                        value={selectedUserRole}
                      />
                    </StyledFlex>
                  );
                }

                return (
                  <TextInput
                    key={index}
                    type={item.type}
                    label={item.label}
                    id={item.name}
                    onInputChange={handleFormChange}
                    value={getInputValue(item.name)}
                    bottomSpace={item.bottomSpace}
                    isError={!!errorFields?.[item.name]}
                    errorMessage={errorFields?.[item.name]?.errorMessage}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => handleBlurInput(event, item.validation)}
                    disabled={selectedAffiliateData ? true : false}
                  />
                );
              }
            })}
            <Button
              className="save-button"
              variant="primary"
              buttonText={selectedAffiliateData ? 'UPDATE ROLE' : 'SEND INVITATION'}
              onClick={handleUpdate}
            />
          </StyledFormWrapper>
        </StyledAddNewUserWrapper>
      </StyledAccountDetailContainer>
    </>
  );
};

export default AddNewUser;
