import React, { LazyExoticComponent, Suspense, useLayoutEffect, useState } from 'react';

import { Route, Router, Routes } from 'react-router-dom';
import { BrowserHistory } from 'history';
import history from '@src/common/history';
import { ROUTE_METADATA } from '@common/constants/routeConstants';
import { useSelector } from 'react-redux';
import { getCommonState } from '@store/selectors/common';
import FullPageLoader from '@components/atoms/Loaders/FullPageLoader';
import NotFound from './notFound';
import AddNewAffiliateUser from './affiliateAccount/addNewUser';
import { PERMISSIONS, canView } from '@common/utils/appConfig';

import '../index.css';

const Login = React.lazy(() => import('./authentication/login'));
const Signup = React.lazy(() => import('./authentication/signup'));
const AffiliatePartners = React.lazy(() => import('./affiliatePartners'));
const AffiliateMemberInvoices = React.lazy(() => import('./affiliatePartners/memberInvoices'));
const AffiliateCodes = React.lazy(() => import('./affiliateCodes'));
const Dashboard = React.lazy(() => import('./dashboard'));
const AccountSettings = React.lazy(() => import('./affiliateAccount'));
const AffiliateDetail = React.lazy(() => import('./affiliatePartners/affiliateDetail'));
const AffiliateCodeDetail = React.lazy(() => import('./affiliateCodes/affiliateCodeDetail'));

interface CustomRouterProps {
  basename?: string;
  children?: JSX.Element;
  history: BrowserHistory;
}

interface IValidatorProps {
  name: PERMISSIONS;
  component: LazyExoticComponent<() => JSX.Element>;
}

const ValidatorComponent = (props: IValidatorProps) => {
  const { component: ComponentToRender, name } = props;
  if (!canView(name)) {
    return <NotFound />;
  }

  return (
    <Suspense fallback={<div>Loading</div>}>
      <ComponentToRender />
    </Suspense>
  );
};

const CustomRouter = (props: CustomRouterProps) => {
  const [state, setAction] = useState({ action: props.history.action, location: props.history.location });

  useLayoutEffect(() => props.history.listen(setAction), [props.history]);

  return (
    <Router
      basename={props.basename}
      children={props.children}
      location={state.location}
      navigationType={state.action}
      navigator={props.history}
    />
  );
};

const App = () => {
  const commonState = useSelector(getCommonState);

  return (
    <CustomRouter history={history}>
      <div className="app-container">
        {commonState.loader ? <FullPageLoader /> : null}
        <Routes>
          <Route
            path={ROUTE_METADATA.LOGIN.path}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_METADATA.SIGNUP.path}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <Signup />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_METADATA.AFFILIATE_PARTNERS.path}
            element={<ValidatorComponent name={PERMISSIONS.AFFILIATE_PARTNERS} component={AffiliatePartners} />}
          />
          <Route
            path={ROUTE_METADATA.AFFILIATE_MEMBER_INVOICES.path}
            element={<ValidatorComponent name={PERMISSIONS.AFFILIATE_PARTNERS} component={AffiliateMemberInvoices} />}
          />
          <Route
            path={ROUTE_METADATA.AFFILIATE_CODES.path}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <AffiliateCodes />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_METADATA.AFFILIATE_CODE_DETAIL.path}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <AffiliateCodeDetail />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_METADATA.DASHBOARD.path}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_METADATA.ACCOUNT_DETAIL.path}
            element={<ValidatorComponent name={PERMISSIONS.ACCOUNT_SETTINGS} component={AccountSettings} />}
          />
          <Route path={ROUTE_METADATA.ADD_NEW_AFFILIATE_USER.path} element={<AddNewAffiliateUser />} />
          <Route
            path={ROUTE_METADATA.AFFILIATE_DETAIL.path}
            element={<ValidatorComponent name={PERMISSIONS.AFFILIATE_PARTNERS} component={AffiliateDetail} />}
          />
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </div>
    </CustomRouter>
  );
};

export default App;
