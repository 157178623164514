import { actionCreator } from '@store/actionCreator';
import { CUSTOM_CODE_STATUS, ICodeTypes, IGenerateCodePayload, IValidateCodePayload } from '@store/types/common';
import CONSTANTS from '../constants';

export default {
  showLoader: actionCreator<void>(CONSTANTS.SHOW_LOADER),
  removeLoader: actionCreator<void>(CONSTANTS.REMOVE_LOADER),
  getCodeTypes: actionCreator<void>(CONSTANTS.GET_CODE_TYPES),
  setCodeTypes: actionCreator<ICodeTypes[]>(CONSTANTS.SET_CODE_TYPES),
  validateCode: actionCreator<IValidateCodePayload>(CONSTANTS.VALIDATE_CODE),
  setCustomCodeStatus: actionCreator<CUSTOM_CODE_STATUS>(CONSTANTS.SET_CUSTOM_CODE_STATUS),
  generateCode: actionCreator<IGenerateCodePayload>(CONSTANTS.GENERATE_CODE),
  generateCodeToggle: actionCreator<boolean>(CONSTANTS.GENERATE_CODE_TOGGLE)
};
