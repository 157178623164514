import { IIconProps } from './index';

export default (props: IIconProps) => (
  <svg
    width={props?.width || 15}
    height={props?.height || 15}
    fill={props?.color || 'currentColor'}
    className={props.className || ''}
    viewBox="0 0 15 15"
  >
    <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="7.411" cy="7.411" rx="7.411" ry="7.411" fill="#00ff19"/>
  </svg>
);
