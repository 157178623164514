import { useEffect, useState } from 'react';
import { DateRangePicker } from '@components/atoms';
import { formatDate, getAndSetQueryParams, getTimeStamp, replaceQueryParams } from '@common/utils';
import { addDays, addMonths, addYears } from 'date-fns';
import history from '@src/common/history';

import {
  StyledDateDropdown,
  StyledDateDropdownField,
  StyledDateDropdownWrapper,
  StyledDateLabel,
  StyledDateWrapper,
  StyledListItem
} from './style';

export type IDateRangeType = 'show_all' | 'last_30_days' | 'last_quarter' | 'last_year' | 'custom_date_range';

export interface IProps {
  label?: string;
  onDateChange: () => void;
  defaultRange?: IDateRangeType;
}

export interface IRangeSortOptions {
  label: string;
  name: IDateRangeType;
}

export const DATE_SORT_OPTIONS: IRangeSortOptions[] = [
  { label: 'Show All', name: 'show_all' },
  { label: 'Last 30 days', name: 'last_30_days' },
  { label: 'Last Quarter', name: 'last_quarter' },
  { label: 'Last Year', name: 'last_year' }
];

const DateFilter = (props: IProps) => {
  const { onDateChange, label, defaultRange } = props;
  const [dateDropdownToggle, setDateDropdownToggle] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));

  useEffect(() => {
    getDefaultDate();
  }, []);

  const getDefaultDate = () => {
    if (!queryParameters.dateRangeType) {
      const { from, to } = getFromAndToDateForDateRange(defaultRange || 'last_30_days');
      replaceQueryParams(getAndSetQueryParams({ from, to, dateRangeType: defaultRange || 'last_30_days' }));
    } else {
      const fromDate = new Date(Number(queryParameters.from) * 1000);
      const toDate = new Date(Number(queryParameters.to) * 1000);

      queryParameters.dateRangeType === 'custom_date_range' && setDateRange([fromDate, toDate]);
    }
  };

  const getFromAndToDateForDateRange = (dateRangeType: string) => {
    let lastDate;
    if (dateRangeType === 'last_30_days') {
      lastDate = addDays(new Date(), -30);
    }
    if (dateRangeType === 'last_quarter') {
      lastDate = addMonths(new Date(), -3);
    }
    if (dateRangeType === 'last_year') {
      lastDate = addYears(new Date(), -1);
    }
    let from = getTimeStamp(lastDate);
    let to = getTimeStamp(new Date());
    if (dateRangeType === 'show_all') {
      from = undefined;
      to = undefined;
    }

    return { from, to };
  };

  const handleDateSortOptionClick = (item: IRangeSortOptions) => {
    setDateDropdownToggle(false);
    if (item.name === 'custom_date_range') {
      return;
    }
    const { from, to } = getFromAndToDateForDateRange(item.name);
    setDateRange([null, null]);
    replaceQueryParams(getAndSetQueryParams({ from, to, dateRangeType: item.name }));
    onDateChange();
  };

  const handleDateChange = (dateRangeParam: [Date | null, Date | null]) => {
    const [startDate, endDate] = dateRangeParam;
    setDateRange(dateRangeParam);
    if (startDate && endDate) {
      const from = getTimeStamp(startDate);
      const to = getTimeStamp(endDate);
      replaceQueryParams(getAndSetQueryParams({ from, to, dateRangeType: 'custom_date_range' }));
      onDateChange();
    }
  };

  const getDateRangeType = () => {
    const selectedDateRange = DATE_SORT_OPTIONS.find(item => item.name === queryParameters.dateRangeType);
    if (selectedDateRange && selectedDateRange.name !== 'custom_date_range') {
      return selectedDateRange.label;
    } else {
      if (queryParameters.from && queryParameters.to) {
        const startDateString = formatDate(Number(queryParameters.from), 'dd/MM/YYY');
        const endDateString = formatDate(Number(queryParameters.to), 'dd/MM/YYY');

        return `${startDateString} - ${endDateString}`;
      }
    }

    return 'dd/mm/yy - dd/mm/yy';
  };

  return (
    <StyledDateWrapper data-testid={'data_range_filter'}>
      {label ? <StyledDateLabel>{label}</StyledDateLabel> : null}
      <StyledDateDropdownWrapper>
        <StyledDateDropdownField onClick={() => setDateDropdownToggle(!dateDropdownToggle)}>
          <span className="date-placeholder">{getDateRangeType()}</span>
        </StyledDateDropdownField>
        {dateDropdownToggle ? (
          <StyledDateDropdown>
            <ul>
              {DATE_SORT_OPTIONS.map(item => (
                <StyledListItem
                  isSelected={queryParameters.dateRangeType === item.name}
                  onClick={() => handleDateSortOptionClick(item)}
                  key={item.name}
                >
                  {item.label}
                </StyledListItem>
              ))}
              <StyledListItem isSelected={queryParameters.dateRangeType === 'custom_date_range'} >
                <DateRangePicker
                  dateRange={dateRange}
                  onSelectDate={handleDateChange}
                  id={'custom_date_range'}
                  withPortal={true}
                  placeholder={'Custom range'}
                  onCalendarClose={() => setDateDropdownToggle(false)}
                />
              </StyledListItem>
            </ul>
          </StyledDateDropdown>
        ) : null}
      </StyledDateDropdownWrapper>
    </StyledDateWrapper>
  );
};

export default DateFilter;
