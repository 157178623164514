export const namespace = 'COMMON_';

export default {
  SHOW_LOADER: `${namespace}SHOW_LOADER`,
  REMOVE_LOADER: `${namespace}REMOVE_LOADER`,
  GET_CODE_TYPES: `${namespace}GET_CODE_TYPES`,
  SET_CODE_TYPES: `${namespace}SET_CODE_TYPES`,
  VALIDATE_CODE: `${namespace}VALIDATE_CODE`,
  SET_CUSTOM_CODE_STATUS: `${namespace}SET_CUSTOM_CODE_STATUS`,
  GENERATE_CODE: `${namespace}GENERATE_CODE`,
  GENERATE_CODE_TOGGLE: `${namespace}GENERATE_CODE_TOGGLE`
};
