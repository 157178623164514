import { call, put, takeLatest } from '@redux-saga/core/effects';
import CONSTANTS from './constants';
import {
  addNewUserService,
  getAccountDetailService,
  getAffiliateTeamService,
  getUserRolesService,
  updateAccountDetailService,
  updateMemberStatusService,
  updateUserService
} from './services';
import { logError } from '@store/utils';
import { IAxiosResponse, IGenericPageListResponse } from '@common/types';
import { IAccountDetailState, IAddNewUserPayload, ITeamPageData, IUpdateAccountPayload, IUpdateMemberStatusPayload, IUpdateUserRolePayload } from './types';
import actions from './actions';
import { IGetListParams } from '@src/common/types';
import { USER_ROLE_TYPES } from '@common/utils/appConfig';
import history from '@src/common/history';
import { ROUTE_METADATA } from '@common/constants/routeConstants';

export function* getAccountDetail(): Generator {
  try {
    const response = (yield call(getAccountDetailService)) as IAxiosResponse<IAccountDetailState>;
    if (response && response.success && response.payload) {
      yield put(actions.setAccountDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* updateAccountDetailSaga({ payload }: { type: string; payload: IUpdateAccountPayload }): Generator {
  try {
    const response = (yield call(updateAccountDetailService, payload)) as IAxiosResponse<null>;

    if (response && response.success && response.payload) {
      yield put(actions.setAccountDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* getAffiliateTeamSaga({ payload }: { payload: IGetListParams; type: string }): Generator {
  try {
    const response = (yield call(getAffiliateTeamService, payload)) as IAxiosResponse<IGenericPageListResponse<ITeamPageData>>;
    if (response && response.success && response.payload) {
      yield put(actions.setAffiliateTeam(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* addNewUserSaga({ payload }: { payload: IAddNewUserPayload; type: string }): Generator {
  try {
    const response = (yield call(addNewUserService, payload)) as IAxiosResponse<IAddNewUserPayload>;
    if (response && response.success && response.payload) {
      history.push(`${ROUTE_METADATA.ACCOUNT_DETAIL.path}?activeTab=1`);
    }
  } catch (error) {
    logError(error);
  }
}

export function* updateMemberStatusSaga({ payload }: { payload: IUpdateMemberStatusPayload; type: string }): Generator {
  try {
    const response = (yield call(updateMemberStatusService, payload)) as IAxiosResponse<null>;
    if (response && response.success) {
      yield put(actions.updateTeamMemberStatusInList(payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* getUserRolesSaga(): Generator {
  try {
    const response = (yield call(getUserRolesService)) as IAxiosResponse<USER_ROLE_TYPES[]>;
    if (response && response.success) {
      yield put(actions.setUserRoles(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* updateUserSaga({ payload }: { payload: IUpdateUserRolePayload; type: string }): Generator {
  try {
    const response = (yield call(updateUserService, payload)) as IAxiosResponse<IUpdateUserRolePayload>;
    if (response && response.success && response.payload) {
      history.push(`${ROUTE_METADATA.ACCOUNT_DETAIL.path}?activeTab=1`);
    }
  } catch (error) {
    logError(error);
  }
}

function* watcherSaga(): Generator {
  yield takeLatest(CONSTANTS.GET_ACCOUNT_DETAIL, getAccountDetail);
  yield takeLatest(CONSTANTS.UPDATE_ACCOUNT_DETAIL, updateAccountDetailSaga);
  yield takeLatest(CONSTANTS.GET_AFFILIATE_TEAM, getAffiliateTeamSaga);
  yield takeLatest(CONSTANTS.ADD_NEW_USER, addNewUserSaga);
  yield takeLatest(CONSTANTS.UPDATE_MEMBER_STATUS, updateMemberStatusSaga);
  yield takeLatest(CONSTANTS.GET_USER_ROLES, getUserRolesSaga);
  yield takeLatest(CONSTANTS.UPDATE_USER, updateUserSaga);
}

export default watcherSaga;
