export enum INPUT_TYPE {
  TEXT = 'text',
  PHONE = 'phone',
  EMAIL = 'email',
  DROP_DOWN = 'dropdown',
  NUMERIC = 'numeric',
  COUNTRY = 'country',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  SELECT = 'select',
}

export enum LOGIN_REDIRECTION {
  DASHBOARD = 'DASHBOARD',
  SIGNUP = 'SIGNUP',
  COMPANY_SIGNUP = 'COMPANY_SIGNUP',
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  LOGIN = 'LOGIN'
}
