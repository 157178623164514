import { format, getUnixTime } from 'date-fns';
import { LOGIN_REDIRECTION } from '@common/enums';
import history from '@src/common/history';
import { ROUTE_METADATA } from '@common/constants/routeConstants';
import { SIGNUP_STEPS } from '@src/pages/authentication/signup/enum';

/**
 * takes a object
 * traverse in each key and check if the key is empty, null, undefined
 * if found object key does't have any value then delete that key from the object
 * finally returns the object with the keys which actually containing some values
 */
export const cleanObject = (object: Object): Object => {
  Object.entries(object).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      cleanObject(value);
    }
    if (
      (value && typeof value === 'object' && !Object.keys(value).length) ||
      value === null ||
      value === undefined ||
      value.length === 0
    ) {
      delete object[key];
    }
  });

  return object;
};

export const formatDate = (date?: Date | number | null, dateFormat: string = 'dd MMM yyyy') => {
  if (date) {
    const dateTime = typeof date === 'number' ? date * 1000 : date;

    return format(new Date(dateTime), dateFormat);
  }
};

export const getTimeStamp = (date?: Date | null) => (date ? getUnixTime(date) : undefined);

export const getAndSetQueryParams = (queryParams: Object): string => {
  const existingQueryParams = Object.fromEntries(new URLSearchParams(location.search));
  const queryParamsToSet = {
    ...existingQueryParams,
    ...queryParams
  };
  if(queryParamsToSet && Object.keys(queryParamsToSet)?.length) {
    const cleanedObj = cleanObject(queryParamsToSet);
    const queryString = Object.keys(cleanedObj).map(key => `${key}=${cleanedObj[key]}`).join('&');

    return queryString;
  }

  return '';
};

export const getQueryString = (queryParams?: Object | void | null): string => {
  if(queryParams && Object.keys(queryParams)?.length) {
    const cleanedObj = cleanObject(queryParams);
    const queryString = Object.keys(cleanedObj).map(key => `${key}=${cleanedObj[key]}`).join('&');

    return queryString ? `?${queryString}` : '';
  }

  return '';
};

export const handleLoginSignupRedirection = (step: LOGIN_REDIRECTION, display_message?: string) => {
  switch(step) {
    case LOGIN_REDIRECTION.LOGIN:
      history.replace(`${ROUTE_METADATA.LOGIN.path}`);
      break;
    case LOGIN_REDIRECTION.DASHBOARD:
      history.replace(`${ROUTE_METADATA.DASHBOARD.path}`);
      break;
    case LOGIN_REDIRECTION.COMPANY_SIGNUP:
      history.replace(`${ROUTE_METADATA.SIGNUP.path}?currentStep=${SIGNUP_STEPS.COMPANY_DETAIL}`);
      break;
    case LOGIN_REDIRECTION.APPROVAL_PENDING:
      history.replace(`${ROUTE_METADATA.SIGNUP.path}?currentStep=${SIGNUP_STEPS.APPROVAL_STATUS}`, {
        display_message
      });
      break;
    default:
      history.replace(`${ROUTE_METADATA.SIGNUP.path}?currentStep=${SIGNUP_STEPS.BASIC_DETAIL}`);
      break;
  }
};

export const replaceQueryParams = (queryParams: string) => {
  history.replace({
    pathname: history.location.pathname,
    search: queryParams
  });
};
