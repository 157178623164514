/** enums starts */

export enum CUSTOM_CODE_STATUS  {
  valid ='valid',
  error = 'error',
  noCheck = 'not-checked'
}

/** enums ends */

export interface ICodeTypes {
  code_type: string;
  offers: IOfferTypes[];
}

export interface ICodeTypesResponse {
  success: boolean;
  payload: ICodeTypes[];
}

export interface IOfferTypes {
  offer_type: string;
  offer_summary: string;

}

export interface ICommonState {
  loader: boolean;
  codeTypes: ICodeTypes[];
  customCodeStatus: CUSTOM_CODE_STATUS;
  isOpenGenerateCode: boolean;
};

export interface IValidateCodePayload {
  code_name: string;
}

export interface IValidateCodeResponse {
  success: boolean;
  payload: {
    exists: boolean;
  };
}

export interface IGenerateCodePayload {
  code_type: string;
  code: string;
  offer_type: string;
  expiry_date?: number;
}

export interface IGenerateCodeResponse {
  success: boolean;
  payload: {
    code_batch_id: number;
  };
}
