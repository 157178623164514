import { all } from '@redux-saga/core/effects';
import authSaga from '@src/pages/authentication/store/sagas';
import affiliateCodesSaga from '@src/pages/affiliateCodes/store/sagas';
import redemption from '@src/pages/dashboard/store/sagas';
import affiliateAccount from '@src/pages/affiliateAccount/store/sagas';
import affiliatePartners from '@src/pages/affiliatePartners/store/sagas';

import commonSaga from './common';

export default function* rootSaga(): Generator {
  yield all([
    authSaga(),
    commonSaga(),
    affiliateCodesSaga(),
    redemption(),
    affiliateAccount(),
    affiliatePartners()
  ]);
}
