import { AnyAction, Reducer } from 'redux';
import { IPageListData, IRedemptionState } from './types';
import CONSTANTS from './constants';
import withProduce from '@src/store/withProduce';
import initialState from './state';
import { IGenericPageListResponse } from '@common/types';

const reducer = {
  [CONSTANTS.SET_REDEMPTION_LIST]: (state: IRedemptionState, payload: IGenericPageListResponse<IPageListData>) => {
    state.redemptionListData = payload;
  },
  [CONSTANTS.SHOW_REDEEM_OFFER] : (state: IRedemptionState, toggle: boolean) => {
    state.isOpenRedeemCode = toggle;
  },
  [CONSTANTS.SET_REDEEM_LINK] : (state: IRedemptionState, payload: string) => {
    state.redeemCodeLink = payload;
  }
};

export default withProduce(initialState, reducer) as Reducer<IRedemptionState, AnyAction>;
