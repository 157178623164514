import styled from 'styled-components';

export const StyledWrapper = styled.div<{ bottomSpace?: number; topSpace?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ bottomSpace }) => (bottomSpace ? `${bottomSpace}px` : '0px')};
  margin-top: ${({ topSpace }) => (topSpace ? `${topSpace}px` : '0px')};
  width: 100%;
  .bm-country-picker {
    width: 100%;
    height: 37px;
    border-radius: 5px;
    outline: none;
    padding: 0 10px 0 10px;
    box-sizing: border-box;
  }
  .error {
    border: 1.5px solid ${({ theme }) => theme.colors.warning};
  }
`;

export const StyledLabel = styled.label`
  margin-bottom: 13px;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.62px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledError = styled.span`
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.62px;
  color: ${({ theme }) => theme.colors.warning};
  margin-top: 5px;
`;
