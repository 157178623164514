import common from './common';
import authentication from '@src/pages/authentication/store/reducers';
import affiliateCode from '@src/pages/affiliateCodes/store/reducers';
import redemption from '@src/pages/dashboard/store/reducers';
import affiliateAccount from '@src/pages/affiliateAccount/store/reducers';
import affiliatePartners from '@src/pages/affiliatePartners/store/reducers';

export const rootReducer = {
  common,
  authentication,
  affiliateCode,
  redemption,
  affiliateAccount,
  affiliatePartners
};

export default rootReducer;
