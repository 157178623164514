import { getUserDetail } from '@common/utils/localStorageUtils';

export enum PERMISSIONS {
  AFFILIATE_PARTNERS = 'AFFILIATE_PARTNERS',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  ADD_CODES = 'ADD_CODES',
  BLOCK_UNBLOCK_USER = 'BLOCK_UNBLOCK_USER',
  UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
}

export enum USER_ROLE_TYPES {
  VIEWER = 'VIEWER',
  BODYMAPP_ADMIN = 'BODYMAPP_ADMIN',
  ACCOUNT_OWNER = 'ACCOUNT_OWNER',
  EDITOR = 'EDITOR',
}

export const getRoles = () => {
  const { AFFILIATE_PARTNERS, ACCOUNT_SETTINGS, ADD_CODES, BLOCK_UNBLOCK_USER, UPDATE_USER_ROLE } = PERMISSIONS;
  const roles = getUserDetail()?.roles || [];
  const USER_ROLES = {
    [USER_ROLE_TYPES.BODYMAPP_ADMIN]: {
      VIEW: [ACCOUNT_SETTINGS],
      EDIT: [AFFILIATE_PARTNERS, BLOCK_UNBLOCK_USER]
    },
    [USER_ROLE_TYPES.ACCOUNT_OWNER]: {
      VIEW: [],
      EDIT: [ACCOUNT_SETTINGS, ADD_CODES, BLOCK_UNBLOCK_USER, UPDATE_USER_ROLE]
    },
    [USER_ROLE_TYPES.VIEWER]: {
      VIEW: [ACCOUNT_SETTINGS],
      EDIT: []
    },
    [USER_ROLE_TYPES.EDITOR]: {
      VIEW: [ACCOUNT_SETTINGS],
      EDIT: [ADD_CODES, BLOCK_UNBLOCK_USER]
    }
  };

  let VIEW: PERMISSIONS[] = [];
  let EDIT: PERMISSIONS[] = [];

  for(const item of roles) {
    if(USER_ROLES[item]) {
      VIEW = [...VIEW, ...USER_ROLES[item].VIEW];
      EDIT = [...EDIT, ...USER_ROLES[item].EDIT];
    }
  }

  return { VIEW, EDIT };
};

export const canView = (key: PERMISSIONS) => {
  const { VIEW, EDIT } = getRoles();
  const roles = [...VIEW, ...EDIT];

  return roles.find(item => item === key);
};

export const canEdit = (key: PERMISSIONS) => {
  const { EDIT } = getRoles();

  return EDIT.find((item: string) => item === key);
};
