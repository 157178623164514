import apiEndpoints from '@src/common/constants/apiEndpoints';
import apiCaller from '@src/store/apiCaller';
import { SIGNUP_STEPS } from '@src/pages/authentication/signup/enum';
import { IAxiosResponse } from '@common/types';

import {
  ILoginPayload,
  ILoginResponse,
  ISignupPayload,
  ISignupResponse
} from './types';

export const requestLoginService = async (payload: ILoginPayload): Promise<IAxiosResponse<ILoginResponse> | Error> => {
  try {
    const { url } = apiEndpoints.AUTHENTICATION.LOGIN;

    return await apiCaller.post(url, payload);
  } catch (e) {
    throw e;
  }
};

export const requestSignupService = async (payload: ISignupPayload): Promise<IAxiosResponse<ISignupResponse> | Error> => {
  try {
    const { url } =
      payload.currentStep === SIGNUP_STEPS.COMPANY_DETAIL
        ? apiEndpoints.AUTHENTICATION.AFFILIATES_SIGNUP
        : apiEndpoints.AUTHENTICATION.USER_SIGNUP;

    return await apiCaller.post(url, payload);
  } catch (e) {
    throw e;
  }
};

