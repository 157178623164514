import { AnyAction, Reducer } from 'redux';
import { IAffiliateMemberInvoice, IAffiliatePartnersState, IPageListData, IPartnerDetailState } from './types';
import CONSTANTS from './constants';
import withProduce from '@src/store/withProduce';
import initialState from './state';
import { IGenericPageListResponse } from '@common/types';

const reducer = {
  [CONSTANTS.SET_AFFILIATE_PARTNERS]: (state: IAffiliatePartnersState, payload: IGenericPageListResponse<IPageListData>) => {
    state.partnersList = payload;
  },
  [CONSTANTS.SET_PARTNER_DETAIL]: (state: IAffiliatePartnersState, payload: IPartnerDetailState) => {
    state.partnerDetail = payload;
  },
  [CONSTANTS.SET_MEMBER_INVOICES]: (state: IAffiliatePartnersState, payload: IGenericPageListResponse<IAffiliateMemberInvoice>) => {
    state.memberInvoicesList = payload;
  }
};

export default withProduce(initialState, reducer) as Reducer<IAffiliatePartnersState, AnyAction>;
