import { call, put, takeLatest } from '@redux-saga/core/effects';
import CONSTANTS from './constants';
import {
  ICodeBatchesListData,
  ICodesListData,
  IGetCodeDetailParams,
  IGetCodeDetailState,
  ISetCodeStatusPayload
} from './types';
import { getAffiliateCodeBatchDetailService, getAffiliateCodeBatchesService, getAffiliateCodesService, setCodeStatusService } from './services';
import { logError } from '@store/utils';
import { IAxiosListResponse, IAxiosResponse, IGetListParams } from '@common/types';
import actions from './actions';

export function* getAffiliateCodeBatchesSaga({ payload }: { payload: IGetListParams; type: string }): Generator {
  try {
    const response = (yield call(getAffiliateCodeBatchesService, payload)) as IAxiosListResponse<ICodeBatchesListData>;
    if (response && response.success && response.payload) {
      yield put(actions.setAffiliateCodeBatches(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* getAffiliateCodeBatchDetailSaga({ payload }: { payload: IGetCodeDetailParams; type: string }): Generator {
  try {
    const response = (yield call(getAffiliateCodeBatchDetailService, payload)) as IAxiosResponse<IGetCodeDetailState>;
    if (response && response.success && response.payload) {
      yield put(actions.setAffiliateCodeBatchDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* setCodeStatusSaga({ payload }: { payload: ISetCodeStatusPayload; type: string }): Generator {
  try {
    const response = (yield call(setCodeStatusService, payload)) as IAxiosResponse<IGetCodeDetailState>;
    if (response && response.success && response.payload) {
      yield put(actions.setAffiliateCodeBatchDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* getAffiliateCodesSaga({ payload }: { payload: IGetListParams; type: string }): Generator {
  try {
    const response = (yield call(getAffiliateCodesService, payload)) as IAxiosListResponse<ICodesListData>;
    if (response && response.success && response.payload) {
      yield put(actions.setAffiliateCodes(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

function* watcherSaga(): Generator {
  yield takeLatest(CONSTANTS.GET_AFFILIATE_CODE_BATCHES, getAffiliateCodeBatchesSaga);
  yield takeLatest(CONSTANTS.GET_CODE_BATCH_DETAIL, getAffiliateCodeBatchDetailSaga);
  yield takeLatest(CONSTANTS.SET_CODE_STATUS, setCodeStatusSaga);
  yield takeLatest(CONSTANTS.GET_AFFILIATE_CODES, getAffiliateCodesSaga);
}

export default watcherSaga;
