import { colors } from '@theme';
import styled from 'styled-components';

export const StyledCSVButton = styled.div`
  max-width: 1200px;
  width: 90%;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 20px 0;
  .download-button {
    cursor: pointer;
    background: ${colors.persianBlue};
    width: 236px;
    height: 43px;
    box-shadow: 0px 5px 5px ${colors.shadowBlack};
    border-radius: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    span {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.05px;
      font-family: bm-regular;
      margin-right: 24px;
      color: ${colors.white};
    }
  }
`;
