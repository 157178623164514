const namespace = 'AFFILIATE_ACCOUNT_';

export default {
  GET_ACCOUNT_DETAIL: `${namespace}GET_ACCOUNT_DETAIL`,
  SET_ACCOUNT_DETAIL: `${namespace}SET_ACCOUNT_DETAIL`,
  GET_AFFILIATE_TEAM: `${namespace}GET_AFFILIATE_TEAM`,
  SET_AFFILIATE_TEAM: `${namespace}SET_AFFILIATE_TEAM`,
  UPDATE_ACCOUNT_DETAIL: `${namespace}UPDATE_ACCOUNT_DETAIL`,
  ADD_NEW_USER: `${namespace}ADD_NEW_USER`,
  UPDATE_MEMBER_STATUS: `${namespace}UPDATE_MEMBER_STATUS`,
  UPDATE_TEAM_MEMBER_STATUS_IN_LIST: `${namespace}UPDATE_TEAM_MEMBER_STATUS_IN_LIST`,
  GET_USER_ROLES: `${namespace}GET_USER_ROLES`,
  SET_USER_ROLES: `${namespace}SET_USER_ROLES`,
  UPDATE_USER: `${namespace}UPDATE_USER`
};
