import { IFormFieldProp, ISignupForm } from '@common/types';
import { INPUT_TYPE } from '@common/enums';
export const PASSWORD_REGEX = /^.{6,}$/g;
export const STRING_WITH_COMMA_DOT_HYPHEN = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;
// eslint-disable-next-line
export const EMAIL_REGEX = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/;
export const ONLY_CHARACTERS = /^[a-zA-Z ]+(([a-zA-Z ])?[a-zA-Z ]*)*$/g;

export const PASSWORD_RULES =
  'Ensure password has minimum six characters';

export const SIGNUP_FORM_FIELDS: ISignupForm = {
  basicDetail: [
    [
      {
        name: 'first_name',
        label: 'First name*',
        type: INPUT_TYPE.TEXT,
        placeholder: '',
        bottomSpace: 12,
        validation: {
          mandatory: true,
          type: 'regex',
          value: ONLY_CHARACTERS,
          message: 'First name is mandatory',
          valueTypeErrorMessage: 'Allowed Only (A - Z | a - z) characters'
        }
      },
      {
        name: 'last_name',
        label: 'Last name*',
        type: INPUT_TYPE.TEXT,
        placeholder: '',
        bottomSpace: 12,
        validation: {
          mandatory: true,
          type: 'regex',
          value: ONLY_CHARACTERS,
          message: 'Last name is mandatory',
          valueTypeErrorMessage: 'Allowed Only (A - Z | a - z) characters'
        }
      }
    ],
    {
      name: 'gender',
      label: 'Gender at birth*',
      type: INPUT_TYPE.CHECKBOX,
      placeholder: '',
      inputs: [{name: 'FEMALE', label: 'Female'}, {name: 'MALE', label: 'Male'}],
      validation: {
        mandatory: true,
        type: 'none',
        value: '',
        message: 'Gender is mandatory to select'
      }
    },
    {
      name: 'date_of_birth',
      label: 'Birth month & year*',
      type: INPUT_TYPE.DATE,
      bottomSpace: 20,
      placeholder: '',
      validation: {
        mandatory: true,
        type: 'none',
        value: '',
        message: 'Date is mandatory'
      }
    },
    {
      name: 'email',
      label: 'User email*',
      type: INPUT_TYPE.TEXT,
      placeholder: '',
      bottomSpace: 34,
      validation: {
        mandatory: true,
        type: 'regex',
        value: EMAIL_REGEX,
        message: 'Email is mandatory',
        valueTypeErrorMessage: 'Email should be like username@domain.com'
      }
    },
    {
      name: 'password',
      label: 'Password*',
      type: INPUT_TYPE.PASSWORD,
      placeholder: '',
      bottomSpace: 11,
      validation: {
        mandatory: true,
        type: 'regex',
        value: PASSWORD_REGEX,
        message: 'Password is mandatory',
        valueTypeErrorMessage: PASSWORD_RULES
      }
    },
    {
      name: 'confirmPassword',
      label: 'Password confirmation*',
      type: INPUT_TYPE.PASSWORD,
      placeholder: '',
      validation: {
        mandatory: true,
        type: 'regex',
        value: PASSWORD_REGEX,
        message: 'Confirm Password is mandatory',
        valueTypeErrorMessage: PASSWORD_RULES
      }
    }
  ],
  companyDetail: [
    {
      name: 'company_name',
      label: 'Company name',
      type: INPUT_TYPE.TEXT,
      placeholder: '',
      bottomSpace: 14,
      validation: {
        mandatory: true,
        type: 'none',
        value: '',
        message: 'Company name is mandatory'
      }
    },
    {
      name: 'company_registration',
      label: 'ABN / Company Registration ID',
      type: INPUT_TYPE.TEXT,
      placeholder: '',
      bottomSpace: 14,
      validation: {
        mandatory: false,
        type: 'none',
        value: '',
        message: 'ABN / Company Registration ID is mandatory'
      }
    },
    {
      name: 'country',
      label: 'Country',
      type: INPUT_TYPE.COUNTRY,
      placeholder: '',
      validation: {
        mandatory: true,
        type: 'none',
        value: '',
        message: 'Country is mandatory'
      }
    }
  ]
};

export const LOGIN_FIELDS: IFormFieldProp[] = [
  {
    name: 'email',
    label: '',
    type: INPUT_TYPE.TEXT,
    placeholder: 'Account email',
    bottomSpace: 12,
    validation: {
      mandatory: true,
      type: 'regex',
      value: EMAIL_REGEX,
      message: 'Email is mandatory',
      valueTypeErrorMessage: 'Email should be like username@domain.com'
    }
  },
  {
    name: 'password',
    label: '',
    type: INPUT_TYPE.PASSWORD,
    placeholder: 'Password',
    bottomSpace: 24,
    validation: {
      mandatory: true,
      type: 'regex',
      value: PASSWORD_REGEX,
      message: 'Password is mandatory',
      valueTypeErrorMessage: PASSWORD_RULES
    }
  }
];

export default {
  SIGNUP_FORM_FIELDS,
  LOGIN_FIELDS
};
