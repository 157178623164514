import { AnyAction, Reducer } from 'redux';
import { IAffiliateCodeState, ICodeBatchesListData, ICodesListData, IGetCodeDetailState } from './types';
import CONSTANTS from './constants';
import withProduce from '@src/store/withProduce';
import initialState from './state';
import { IGenericPageListResponse } from '@common/types';

const reducer = {
  [CONSTANTS.SET_AFFILIATE_CODES]: (state: IAffiliateCodeState, payload: IGenericPageListResponse<ICodesListData>) => {
    state.codesListData = payload;
  },
  [CONSTANTS.SET_AFFILIATE_CODE_BATCHES]: (state: IAffiliateCodeState, payload: IGenericPageListResponse<ICodeBatchesListData>) => {
    state.codeBatchesListData = payload;
  },
  [CONSTANTS.SET_CODE_BATCH_DETAIL]: (state: IAffiliateCodeState, payload: IGetCodeDetailState) => {
    state.codeDetail = payload;
  }
};

export default withProduce(initialState, reducer) as Reducer<IAffiliateCodeState, AnyAction>;
