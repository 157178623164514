import styled from 'styled-components';
import { colors } from '@theme';

export const StyledDateWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 20px;
`;

export const StyledDateLabel = styled.div`
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  font-family: bm-semibold;
  letter-spacing: 0.05px;
  color: ${colors.cloudyGrey};
`;

export const StyledDateDropdownWrapper = styled.div`
  position: relative;
  .date-placeholder {
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03px;
    color: ${colors.cloudyGrey};
  }
`;

export const StyledDateDropdownField = styled.div`
  width: 217px;
  height: 41px;
  background: ${colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px ${colors.shadowBlack};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const StyledDateDropdown = styled.div`
  position: absolute;
  top: 47px;
  min-width: 161px;
  min-height: 104px;
  background: ${colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px ${colors.shadowBlack};
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  ul {
    li {
      list-style: none;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.05px;
      color: ${colors.cloudyGrey};
      cursor: pointer;
    }
    li:not(:last-child) {
      margin-bottom: 10px;
    }
    #custom_date_range {
      width: auto;
      box-shadow: none;
      border: none;
      padding: 0;
      height: auto;
      text-align: left;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.05px;
      color: ${colors.cloudyGrey};
      cursor: pointer;
      outline: none;
    }
  }
`;

export const StyledListItem = styled.li<{ isSelected?: boolean }>`
  color: ${({ isSelected }) => isSelected && colors.aquaBlue} !important;
  input {
    color: ${({ isSelected }) => isSelected && colors.aquaBlue} !important;
  }
`;
