import { useEffect, useRef } from 'react';
import { Button } from '@components/atoms';
import { CloseIcon } from '@staticImages';
import { StyledBody, StyledHeaderActionWrapper, StyledVideoWrapper } from './styles';

export interface IProps {
  url?: string;
  datatestId?: string;
  onClose: () => void;
}

const BMVideo = (props: IProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const {
    datatestId = 'bm-video',
    url = 'https://www.youtube.com/embed/-AWKwjubLK4?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0&autoplay=1'
  } = props;

  useEffect(() => {
    // eslint-disable-next-line
    const handleClickOutside = (event: any) => {
      if (iframeRef.current && !iframeRef.current?.contains(event.target as Node)) {
        props.onClose?.();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.addEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <StyledVideoWrapper data-testid={datatestId}>
      <StyledBody>
        <StyledHeaderActionWrapper>
          <Button dataTestId="close_video_button" variant="transparent" onClick={props.onClose}>
            <img src={CloseIcon} alt="close video button" className="close-icon" />
          </Button>
        </StyledHeaderActionWrapper>
        <iframe ref={iframeRef} width={'100%'} height={'100%'} src={`${url}`} allowFullScreen={true} />
      </StyledBody>
    </StyledVideoWrapper>
  );
};

export default BMVideo;
