import styled from 'styled-components';
import { colors } from '@theme';

export const StyledTableContainer = styled.section`
  max-width: 90%;
  box-sizing: border-box;
`;

export const StyledTableInnerContainer = styled.section`
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  table {
    border-spacing: 8px;
  }
  th,
  td {
    padding: 8px 8px 8px 8px;
    text-align: center;
  }
  .table-head-cell {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .image-wrapper {
      display: flex;
      flex-shrink: 0;
      img {
        object-fit: contain;
        width: 21px;
        height: 16px;
      }
    }
  }
`;

export const StyledTCell = styled.td<{
  isClickable?: boolean;
  columnWidth?: string;
  noBackground?: boolean;
}>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'unset')};
  &.clickable-td {
    text-decoration: underline;
  }
  min-width: ${({ columnWidth }) => columnWidth && columnWidth} !important;
  max-width: ${({ columnWidth }) => columnWidth && columnWidth} !important;
  word-wrap: break-word;
  min-height: 36px;
  background: ${({ noBackground }) =>
    noBackground ? colors.white : `${colors.vistaWhite} 0% 0% no-repeat padding-box`};
  position: ${({ noBackground }) => (noBackground ? 'sticky' : 'static')};
  right: 0;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05px;
  color: ${colors.cloudyGrey};
`;

export const StyledTableHeadCell = styled.th<{ columnWidth?: string; noBackground?: boolean }>`
  min-width: ${({ columnWidth }) => columnWidth && columnWidth} !important;
  max-width: ${({ columnWidth }) => columnWidth && columnWidth} !important;
  word-wrap: break-word;
  min-height: 36px;
  background: ${({ noBackground }) =>
    noBackground ? colors.white : `${colors.persianBlue} 0% 0% no-repeat padding-box`};
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05px;
  color: ${colors.white};
`;

export const StyledTableMainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0 0 0;

  .pagination-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 10px 0 0;
    .page-size-wrapper {
      display: flex;
      align-items: center;
      column-gap: 7px;
      .page-size {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.05px;
        color: ${colors.cloudyGrey};
      }
    }
  }
`;
