import { actionCreator } from '@store/actionCreator';
import CONSTANTS from './constants';
import { IGenericPageListResponse, IGetListParams } from '@common/types';
import { ICodeBatchesListData, ICodesListData, IGetCodeDetailParams, IGetCodeDetailState, ISetCodeStatusPayload } from './types';

export default {
  getAffiliateCodeBatches: actionCreator<IGetListParams | void>(CONSTANTS.GET_AFFILIATE_CODE_BATCHES),
  setAffiliateCodeBatches: actionCreator<IGenericPageListResponse<ICodeBatchesListData>>(CONSTANTS.SET_AFFILIATE_CODE_BATCHES),
  getAffiliateCodeBatchDetail: actionCreator<IGetCodeDetailParams>(CONSTANTS.GET_CODE_BATCH_DETAIL),
  setAffiliateCodeBatchDetail: actionCreator<IGetCodeDetailState>(CONSTANTS.SET_CODE_BATCH_DETAIL),
  setCodeStatus: actionCreator<ISetCodeStatusPayload>(CONSTANTS.SET_CODE_STATUS),
  getAffiliateCodes: actionCreator<IGetListParams | void>(CONSTANTS.GET_AFFILIATE_CODES),
  setAffiliateCodes: actionCreator<IGenericPageListResponse<ICodesListData>>(CONSTANTS.SET_AFFILIATE_CODES)
};
