import { useEffect, useRef, useState } from 'react';
import {
  StyledContainer,
  StyledDatePicker,
  StyledFlex,
  StyledFooterSection,
  StyledForm,
  StyledFormWrapper,
  StyledHeaderActionWrapper,
  StyledInfoText,
  StyledLabel,
  StyledValidateButtonFlexRow,
  StyledValidateCodeWrapper,
  StyledValidateInputWrapper
} from './styles';
import { CheckGreenIconFill, CloseIcon, XWarnIconFill } from '@staticImages';
import { BMCheckbox, Button, DatePicker, SelectDropDown, TextInput } from '@components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import commonActions from '@store/actions/common';
import { getCodeTypesWithOffer, getCommonState } from '@src/store/selectors/common';
import { CUSTOM_CODE_STATUS, IGenerateCodePayload } from '@store/types/common';
import { cleanObject, getTimeStamp } from '@common/utils';

const CODE_MAX_LENGTH = 100;
export interface IProps {
  onClose: () => void;
  isOpen: boolean;
}

export interface IState {
  code_type: string;
  custom_coupon_code: string;
  offer_type: string;
  expiry_date?: Date | null;
  isExpiryEnabled: boolean;
  code_quantity?: number;
}

const InitialState: IState = {
  code_type: '',
  custom_coupon_code: '',
  offer_type: '',
  expiry_date: null,
  isExpiryEnabled: false,
  code_quantity: undefined
};

const GenerateCode = (props: IProps) => {
  const dispatch = useDispatch();

  const { onClose } = props;
  const [formData, setFormData] = useState<IState>(InitialState);
  const codeTypes = useSelector(getCodeTypesWithOffer);
  const { customCodeStatus } = useSelector(getCommonState);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const [codeType, setCodeType] = useState<{label: string; value: string} | null>(null);
  const [offer, setOffer] = useState<{label: string; value: string} | null>(null);

  useEffect(() => {
    dispatch(commonActions.getCodeTypes());
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    const handleClickOutside = (event: any) => {
      if (mainContainerRef.current && !mainContainerRef.current?.contains(event.target as Node)) {
        onClose?.();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.addEventListener('click', handleClickOutside, true);
    };
  }, []);

  const isFormValid = () => {
    if (!formData.code_type || !formData.offer_type) {
      return { isError: true };
    }
    if (formData.isExpiryEnabled && !formData.expiry_date) {
      return { isError: true };
    }
    if (formData.custom_coupon_code && customCodeStatus !== CUSTOM_CODE_STATUS.valid) {
      return { isError: true };
    }

    return { isError: false };
  };

  const handleCheckboxSelect = (isExpiryEnabled: boolean) => {
    if (!isExpiryEnabled) {
      setFormData({ ...formData, expiry_date: null, isExpiryEnabled });
    } else {
      setFormData({ ...formData, isExpiryEnabled });
    }
  };

  const handleFormInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name === 'code_quantity') {
      let code_quantity =  Number(value);
      if(code_quantity > CODE_MAX_LENGTH) {
        code_quantity = CODE_MAX_LENGTH;
      }
      setFormData({ ...formData, [name]: code_quantity });

      return;
    }

    dispatch(commonActions.setCustomCodeStatus(CUSTOM_CODE_STATUS.noCheck));
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectDropdown = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
    if(name === 'code_type') {
      setCodeType({ label: value, value });
    } else {
      setOffer({ label: value, value });
    }
  };

  const handleSubmit = () => {
    dispatch(
      commonActions.generateCode(
        cleanObject({
          code_type: formData.code_type,
          offer_type: formData.offer_type,
          code: formData.custom_coupon_code,
          expiry_date: getTimeStamp(formData.expiry_date),
          code_quantity: formData.code_quantity
        }) as IGenerateCodePayload
      )
    );
  };

  const handleValidateCode = () => {
    dispatch(commonActions.validateCode({ code_name: formData.custom_coupon_code }));
  };

  const ValidateCodeField = () =>
    formData.code_type === 'UNIQUE' ? (
      <StyledValidateInputWrapper>
      <StyledFlex>
        <StyledLabel spaceRight={32}>Code Quantity:</StyledLabel>
        <TextInput
          className="custom-code-input"
          id="code_quantity"
          onInputChange={handleFormInputChange}
          value={formData.code_quantity}
          type="text"
          dataTestId="code_quantity"
          maxLength={3}
          valueType={'number'}
        />
      </StyledFlex>
      </StyledValidateInputWrapper>
    ) : (
      <StyledValidateInputWrapper>
        <StyledFlex>
          <StyledLabel spaceRight={32}>Enter custom code:</StyledLabel>
          <TextInput
            className="custom-code-input"
            id="custom_coupon_code"
            onInputChange={handleFormInputChange}
            value={formData.custom_coupon_code}
            type="text"
            dataTestId="custom_code_input"
            maxLength={12}
          />
        </StyledFlex>
        <StyledValidateButtonFlexRow>
          <StyledInfoText className="enter-coupon">Between 4 - 12 characters.</StyledInfoText>
          <StyledValidateCodeWrapper>
            <Button
              dataTestId="validate_code_button"
              className="validate-button"
              onClick={handleValidateCode}
              buttonText={'VALIDATE'}
              variant="primary"
              disabled={!formData.custom_coupon_code || formData.custom_coupon_code.length < 4}
            />
            <div className="validate-icon-wrapper">
              {customCodeStatus === CUSTOM_CODE_STATUS.valid ? (
                <img className="validate-icon" src={CheckGreenIconFill} alt="check fill" />
              ) : null}
              {customCodeStatus === CUSTOM_CODE_STATUS.error ? (
                <img className="validate-icon" src={XWarnIconFill} alt="x warn fill" />
              ) : null}
            </div>
          </StyledValidateCodeWrapper>
        </StyledValidateButtonFlexRow>
      </StyledValidateInputWrapper>
    );

  const handleSelectDate = (date: Date) => {
    setFormData({ ...formData, expiry_date: date });
  };

  const getOfferOptions = () => codeTypes?.find(item => item.value === formData.code_type)?.offers || [];

  return (
    <StyledContainer>
      <StyledFormWrapper ref={mainContainerRef}>
        <StyledHeaderActionWrapper>
          <Button dataTestId="close_generate_code" variant="transparent" onClick={onClose}>
            <img src={CloseIcon} alt="close button" className="close-icon" />
          </Button>
        </StyledHeaderActionWrapper>
        <div>
          <div className="title-section">Generate a Code</div>
          <StyledForm>
            <StyledInfoText className="required-field-text">*Required fields.</StyledInfoText>
            <StyledFlex>
              <StyledLabel spaceRight={42}>Select code type*:</StyledLabel>
              <SelectDropDown
                onSelect={handleSelectDropdown}
                customClass="generate-code-dropdown"
                name="code_type"
                options={codeTypes || []}
                isDisabled={!codeTypes?.length}
                value={codeType}
              />
            </StyledFlex>
            {ValidateCodeField()}
            <StyledFlex>
              <StyledLabel spaceRight={41}>Select offer type*:</StyledLabel>
              <SelectDropDown
                onSelect={handleSelectDropdown}
                customClass="generate-code-dropdown"
                name="offer_type"
                options={getOfferOptions()}
                isDisabled={!getOfferOptions()?.length}
                value={offer}
              />
            </StyledFlex>
            <StyledFlex rowGap={20}>
              <BMCheckbox
                isChecked={formData.isExpiryEnabled}
                spaceBetweenItem={19}
                isRtlLabel
                id="expiry_date_enabled"
                label="Expiry date:"
                onChangeCheckbox={handleCheckboxSelect}
                dataTestId="expiry_checkbox"
              />
              <StyledDatePicker isDisabled={!formData.isExpiryEnabled}>
                <DatePicker
                  value={formData.expiry_date ? formData.expiry_date : undefined}
                  onSelectDate={handleSelectDate}
                  id={'expiry_date'}
                  isDisabled={!formData.isExpiryEnabled}
                  dataTestId="date-picker"
                  withPortal={true}
                />
              </StyledDatePicker>
              <StyledInfoText>If unchecked, no expiry.</StyledInfoText>
            </StyledFlex>
            <StyledFooterSection>
              <Button
                dataTestId={'generate_code_button'}
                disabled={isFormValid().isError}
                className={'generate-button'}
                onClick={handleSubmit}
                buttonText={'GENERATE'}
                variant="primary"
              />
            </StyledFooterSection>
          </StyledForm>
        </div>
      </StyledFormWrapper>
    </StyledContainer>
  );
};

export default GenerateCode;
