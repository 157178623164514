import { colors } from '@theme';
import styled from 'styled-components';

export const StyledSwitchWrapper = styled.div<{isChecked: boolean}>`
  display: flex;
  align-items: center;

  label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05px;
    font-family: bm-semibold;
    color: ${colors.cloudyGrey};
    margin-right: 24px;
  }
  &.aqua-switch {
    .react-switch-bg {
      background: transparent !important;
      border: 2px solid ${({ isChecked }) => (isChecked ? colors.aquaBlue : colors.grey)} !important;
      margin: 0 !important;
      border-radius: 10px !important;
    }
    .react-switch-handle {
      background-color: ${({ isChecked }) =>
        isChecked ? colors.aquaBlue : colors.grey} !important;
      top: -4px !important;
      transform: ${({ isChecked }) =>
        isChecked ? 'translateX(14px)' : 'translateX(0px)'} !important;
    }
  }
`;
