import React, { useEffect, useLayoutEffect, useState } from 'react';
import { StyledError, StyledInputBox, StyledInputWrapper, StyledLabel, StyledTextInput } from './styles';
import { INPUT_TYPE } from '@src/common/enums';
import { EyeIcon, EyeSlashIcon } from '@components/icons';
import { colors } from '@src/theme';

export interface IProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  bottomSpace?: number;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  errorMessage?: string;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  dataTestId?: string;
  maxLength?: number;
  disabled?: boolean;
  topSpace?: number;
  valueType?: 'number' | 'text';
}

const TextInput = (props: IProps) => {
  const {
    type,
    label,
    placeholder = '',
    onInputChange,
    id,
    bottomSpace,
    className,
    isError,
    errorMessage,
    value,
    onBlur,
    dataTestId,
    maxLength,
    disabled,
    topSpace,
    min,
    max,
    valueType
  } = props;
  const [inputValue, setInputValue] = useState<string | number | undefined>('');
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const NumberRegex = /^[0-9]*$/;
    if(valueType === 'number' && !NumberRegex.test(event.target.value)) {
      event.preventDefault();

      return;
    }
    setInputValue(event.target.value);
    onInputChange(event);
  };

  const handlePasswordVisible = (fieldId?: string) => {
    if (fieldId) {
      const element = document.getElementById(fieldId);
      if (element) {
        element.setAttribute('type', isPasswordVisible ? 'password' : 'text');
        setPasswordVisible(!isPasswordVisible);
      }
    }
  };

  useLayoutEffect(() => {
    if(id) {
      const element = document.getElementById(id);
      if(element) {
        setInputWidth(element.offsetWidth);
      }
    }
  }, [id]);

  return (
    <StyledInputWrapper className={`bm-input-wrapper ${className}`} bottomSpace={bottomSpace} topSpace={topSpace}>
      {label ? <StyledLabel htmlFor={id}>{label}</StyledLabel> : null}
      <StyledInputBox disabled={!!disabled}>
        <StyledTextInput
          className={`${isError ? 'error' : ''}`}
          type={type}
          value={inputValue || ''}
          onChange={handleChange}
          placeholder={placeholder}
          id={id}
          name={id}
          onBlur={onBlur ? onBlur : () => undefined}
          data-testid={dataTestId}
          maxLength={maxLength}
          disabled={!!disabled}
          min={min}
          max={max}
        />
        {type === INPUT_TYPE.PASSWORD ? (
          <button disabled={disabled} className="eye-slash" onClick={() => handlePasswordVisible(id)}>
            {isPasswordVisible ? (
              <EyeIcon width={18} height={15} color={colors.persianBlue} />
            ) : (
              <EyeSlashIcon width={18} height={15} color={colors.persianBlue} />
            )}
          </button>
        ) : null}
      </StyledInputBox>
      {isError ? <StyledError inputWidth={inputWidth}>{`${errorMessage ? `*${errorMessage}` : ''}`}</StyledError> : null}
    </StyledInputWrapper>
  );
};

export default TextInput;
