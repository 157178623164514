import React from 'react';
import { StyledCheckbox, StyledLabel, StyledWrapper } from './styles';

export interface IProps extends React.HTMLProps<HTMLInputElement> {
  onChangeCheckbox?: (value: boolean) => void;
  customClass?: string;
  id: string;
  isRtlLabel?: boolean;
  spaceBetweenItem?: number;
  isChecked?: boolean;
  dataTestId?: string;
  checkedBgColor?: string;
  checkIconColor?: string;
  borderColor?: string;
  borderColorChecked?: string;
  disabled?: boolean;
}

const BMCheckbox = (props: IProps) => {
  const {
    onChangeCheckbox,
    label = '',
    id,
    isRtlLabel = false,
    spaceBetweenItem = 20,
    customClass,
    isChecked,
    dataTestId,
    checkedBgColor,
    checkIconColor,
    borderColor,
    borderColorChecked,
    disabled
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCheckbox?.(event.target.checked);
  };

  return (
    <StyledWrapper disabled={disabled} htmlFor={id} className={customClass || ''}>
      {label && !isRtlLabel ? (
        <StyledLabel disabled={disabled} isRtlLabel={isRtlLabel} spaceBetweenItem={spaceBetweenItem}>
          {label}
        </StyledLabel>
      ) : null}
      <StyledCheckbox
        data-testid={dataTestId}
        type="checkbox"
        onChange={handleChange}
        checked={!!isChecked}
        name={id}
        id={id}
        checkedBgColor={checkedBgColor}
        checkIconColor={checkIconColor}
        borderColor={borderColor}
        borderColorChecked={borderColorChecked}
        disabled={disabled}
      />
      {label && isRtlLabel ? (
        <StyledLabel disabled={disabled} isRtlLabel={isRtlLabel} spaceBetweenItem={spaceBetweenItem}>
          {label}
        </StyledLabel>
      ) : null}
    </StyledWrapper>
  );
};

export default BMCheckbox;
