import { INPUT_TYPE } from '@common/enums';
import { EMAIL_REGEX } from '@common/constants/formFields';
import { IFormFieldProp } from '@common/types';
import { IFormState } from './index';

export const COMPANY_DETAIL: (IFormFieldProp[] | IFormFieldProp)[] = [
  {
    name: 'company_name',
    label: 'Company name',
    type: INPUT_TYPE.TEXT,
    placeholder: '',
    bottomSpace: 12,
    topSpace: 40,
    validation: {
      mandatory: true,
      type: 'none',
      value: '',
      message: 'Company name is mandatory'
    }
  },
  {
    name: 'company_registration',
    label: 'ABN / Company Registration ID',
    type: INPUT_TYPE.TEXT,
    placeholder: '',
    bottomSpace: 12,
    validation: {
      mandatory: false,
      type: 'none',
      value: '',
      message: ''
    }
  },
  {
    name: 'country',
    label: 'Country',
    type: INPUT_TYPE.COUNTRY,
    placeholder: '',
    bottomSpace: 12,
    validation: {
      mandatory: true,
      type: 'none',
      value: '',
      message: 'Country is mandatory'
    }
  }
];

export const ADD_NEW_USER: (IFormFieldProp[] | IFormFieldProp)[] = [
  {
    name: 'email',
    label: 'Account email',
    type: INPUT_TYPE.EMAIL,
    placeholder: '',
    bottomSpace: 15,
    topSpace: 0,
    validation: {
      mandatory: true,
      type: 'regex',
      value: EMAIL_REGEX,
      message: 'Email is mandatory',
      valueTypeErrorMessage: 'Email format is not matching'
    }
  },
  {
    name: 'role',
    label: 'Role permission',
    type: INPUT_TYPE.SELECT,
    placeholder: '',
    bottomSpace: 0,
    topSpace: 0,
    validation: {
      mandatory: true,
      type: 'none',
      value: '',
      message: 'Role is mandatory'
    }
  }
];

export const INITIAL_FORM_STATE: IFormState = {
  company_name: '',
  company_registration: '',
  country: ''
};
