import { AnyAction, Reducer } from 'redux';
import { IAccountDetailState, IAffiliateAccountState, ITeamPageData, IUpdateMemberStatusPayload } from './types';
import CONSTANTS from './constants';
import withProduce from '@src/store/withProduce';
import initialState from './state';
import {IGenericPageListResponse } from '@src/common/types';
import { USER_ROLE_TYPES } from '@common/utils/appConfig';

const reducer = {
  [CONSTANTS.SET_ACCOUNT_DETAIL]: (state: IAffiliateAccountState, payload: IAccountDetailState) => {
    state.accountDetail = payload;
  },
  [CONSTANTS.SET_AFFILIATE_TEAM]: (state: IAffiliateAccountState, payload: IGenericPageListResponse<ITeamPageData>) => {
    state.team = payload;
  },
  [CONSTANTS.UPDATE_TEAM_MEMBER_STATUS_IN_LIST]: (state: IAffiliateAccountState, payload: IUpdateMemberStatusPayload) => {
    const indexOfItem = state.team?.page?.findIndex(item => item.id === payload.user_id);
    state.team.page[indexOfItem].status = payload.status;
  },
  [CONSTANTS.SET_USER_ROLES]: (state: IAffiliateAccountState, payload: USER_ROLE_TYPES[]) => {
    state.userRoles = payload;
  }
};

export default withProduce(initialState, reducer) as Reducer<IAffiliateAccountState, AnyAction>;
