import authentication from '@src/pages/authentication/store/state';
import affiliateCode from '@src/pages/affiliateCodes/store/state';
import redemption from '@src/pages/dashboard/store/state';
import affiliateAccount from '@src/pages/affiliateAccount/store/state';
import affiliatePartners from '@src/pages/affiliatePartners/store/state';

import commonState from './common';

export default () => ({
  common: commonState(),
  authentication: authentication(),
  affiliateCode: affiliateCode(),
  redemption: redemption(),
  affiliateAccount: affiliateAccount(),
  affiliatePartners: affiliatePartners()
});
