import React, { useEffect, useState } from 'react';
import { StyledError, StyledLabel, StyledWrapper } from './style';
import { CountryDropdown } from 'react-country-region-selector';

export interface IProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  bottomSpace?: number;
  id: string;
  isError?: boolean;
  errorMessage?: string;
  onInputChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  topSpace?: number;
}

const CountryPicker = (props: IProps) => {
  const [country, setCountry] = useState<string | undefined>('');
  const { label, bottomSpace, id, isError, errorMessage, onInputChange, value, disabled, topSpace } = props;

  useEffect(() => {
    setCountry(value);
  }, [value]);

  const selectCountry = (val: string) => {
    setCountry(val);
    if(onInputChange) {
      onInputChange(val);
    }
  };

  return (
    <StyledWrapper bottomSpace={bottomSpace} topSpace={topSpace}>
      {label ? <StyledLabel htmlFor={id}>{label}</StyledLabel> : null}
      <CountryDropdown
        id={id}
        value={country || ''}
        onChange={selectCountry}
        classes={`bm-country-picker ${isError && 'error'}`}
        disabled={disabled}
      />
      {isError ? <StyledError>{`${errorMessage ? `*${errorMessage}` : ''}`}</StyledError> : null}
    </StyledWrapper>
  );
};

export default CountryPicker;
