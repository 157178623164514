import { StyledWrapper } from './styles';
import Select, { Props as ISelectProps } from 'react-select';

const defaultOptions = [
  { value: 'OptionA', label: 'Option A' },
  { value: 'OptionB', label: 'Option B' }
];

export interface IProps extends Partial<ISelectProps> {
  onSelect?: (value: string, name: string) => void;
  customClass?: string;
}

export interface IOptionItem {
  label: string;
  value: string;
}

const SelectDropDown = (props: IProps) => {
  const {
    isClearable = false,
    isLoading = false,
    options = defaultOptions,
    name = 'bm_select',
    isDisabled = false,
    isSearchable = true,
    customClass = '',
    value,
    onSelect,
    menuPosition,
    ...rest
  } = props;

  const handleSelectItem = (selectedItem: IOptionItem) => {
    onSelect?.(selectedItem?.value, name);
  };

  return (
    <StyledWrapper className='bm-select-wrapper'>
      <Select
        className={customClass || ''}
        classNamePrefix="bm-select-dropdown"
        defaultValue={options[0]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        options={options}
        id={name}
        value={value}
        menuPosition={menuPosition}
        onChange={selectedItem => handleSelectItem(selectedItem as unknown as IOptionItem)}
        {...rest}
      />
    </StyledWrapper>
  );
};

export default SelectDropDown;
