export enum LOCAL_STORAGE {
  USER_DETAIL = 'USER_DETAIL'
}

export const getLocalStorage = (keyName: LOCAL_STORAGE) => localStorage.getItem(keyName) || '';

export const setLocalStorage = (keyName: LOCAL_STORAGE, value: string | Object) => {
  const valueToSet = typeof value === 'object' ? JSON.stringify(value) : value;
  localStorage.setItem(keyName, valueToSet);
};

export const removeLocalStorage = (keyName: LOCAL_STORAGE) => {
  localStorage.removeItem(keyName);
};

export const getUserDetail = () => {
  const userDetail = getLocalStorage(LOCAL_STORAGE.USER_DETAIL);

  return userDetail ? JSON.parse(userDetail) : null;
};
