import { StyledSwitchWrapper } from './styles';
import Switch from 'react-switch';
import { colors } from '@theme';

export interface IProps {
  isChecked: boolean;
  handleToggle: (value: boolean) => void;
  label?: string|boolean;
  id?: string;
  dataTestId?: string;
  disabled?: boolean;
  onColor?: string;
  handleDiameter?: number;
  height?: number;
  width?: number;
  wrapperClass?: string;
}

const SwitchInput = (props: IProps) => {
  const {
    isChecked = false,
    handleToggle,
    label,
    id = 'bm-switch-input',
    dataTestId = 'bm-switch-button',
    disabled,
    onColor,
    handleDiameter,
    height,
    width,
    wrapperClass  = ''
  } = props;

  return (
    <StyledSwitchWrapper isChecked={isChecked} className={wrapperClass}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Switch
        height={height || 20}
        width={width || 60}
        checkedIcon={false}
        uncheckedIcon={false}
        id={id}
        onChange={handleToggle}
        checked={isChecked}
        handleDiameter={handleDiameter || 30}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        onColor={onColor || colors.persianBlue}
        data-testid={dataTestId}
        disabled={disabled}
      />
    </StyledSwitchWrapper>
  );
};

export default SwitchInput;
