import styled from 'styled-components';
import { colors } from '@theme';

export const StyledContainer = styled.div`
  background-color: ${colors.modalBack};
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledFormWrapper = styled.div`
  width: 80%;
  max-height: 80vh;
  background-color: ${colors.white};
  box-shadow: 0px 10px 10px ${colors.shadowBlack};
  border: 1px solid ${colors.silver};
  border-radius: 17px;
  padding: 15px 15px 40px 30px;
  overflow-y: auto;
  @media only screen and (max-width: 800px) {
    padding: 15px 15px 40px 15px;
  }

  .title-section {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.colors.persianBlue};
    text-align: center;
    margin: 7px 0 28px 0;
  }
`;

export const StyledForm = styled.div`
  .generate-code-dropdown {
    width: 185px;
    height: 44px;
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
  .generate-button {
    width: 201px;
    height: 44px;
    border-radius: 31px;
  }
  .validate-button {
    width: 133px;
    height: 40px;
    background: ${colors.white} 0% 0% no-repeat padding-box;
    border: 1px solid ${({ theme }) => theme.colors.persianBlue};
    border-radius: 31px;
    text-align: center;
    letter-spacing: 0.05px;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.persianBlue};
    margin-right: 16px;
  }
  .validate-icon-wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`;

export const StyledHeaderActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .close-icon {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
`;

export const StyledValidateInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 30px;
  width: 100%;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
  .custom-code-input {
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
    input {
      width: 370px;
      height: 36px;
      background-color: ${colors.vistaWhite};
      border: 1px solid ${colors.silver};
      @media only screen and (max-width: 800px) {
        width: 100%;
      }
    }
  }
`;

export const StyledFlex = styled.div<{ rowGap?: number }>`
  display: flex;
  align-items: center;
  padding-top: 30px;
  @media only screen and (max-width: 800px) {
    row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}px` : 0)};
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

export const StyledValidateButtonFlexRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 43px;
  @media only screen and (max-width: 800px) {
    width: 100%;
    column-gap: 10px;
    justify-content: space-between;
  }
`;

export const StyledLabel = styled.div<{ spaceRight?: number }>`
  font-size: 15px;
  line-height: 20px;
  font-family: bm-bold;
  letter-spacing: 0.05px;
  color: ${({ theme }) => theme.colors.cloudyGrey};
  opacity: 1;
  margin-right: ${({ spaceRight }) => `${spaceRight || 20}px`};
  @media only screen and (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const StyledDatePicker = styled.div<{isDisabled?: boolean}>`
  margin: 0 18px 0 47px;
  opacity: ${({ isDisabled }) => isDisabled ? 0.5 : 1};
  @media only screen and (max-width: 800px) {
    margin: 0;
    width: 100%;
  }
`;

export const StyledFooterSection = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledInfoText = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-family: bm-italic;
  letter-spacing: 0.05px;
  color: ${colors.cloudyGrey};
  &.footer {
    text-align: center;
    margin-top: 15px;
    max-width: 80%;
  }
  &.enter-coupon {
  }
`;

export const StyledValidateCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  .validate-icon {
    width: 21px;
    height: 21px;
    object-fit: contain;
  }
`;
