import { IRedemptionState } from './types';
import { SORT_DIRECTION } from '@common/types';

export default (): IRedemptionState => ({
  redemptionListData: {
    page_size: 0,
    page_number: 0,
    total_pages: 0,
    next_page: 0,
    sort_key: '',
    total: 0,
    extras: {
      total_paying: 0,
      total_redeemed: 0,
      total_offers_redeemed: 0,
      offers_redemption_limit: undefined
    },
    sort_direction: SORT_DIRECTION.ASC,
    sort_options: {
      sort_keys: [],
      sort_directions: []
    },
    page: []
  },
  isOpenRedeemCode: false,
  redeemCodeLink: ''
});
