import {
  ICodeTypesResponse,
  IGenerateCodePayload,
  IGenerateCodeResponse,
  IValidateCodePayload,
  IValidateCodeResponse
} from '@store/types/common';
import apiEndpoints from '@src/common/constants/apiEndpoints';
import apiCaller from '@src/store/apiCaller';

export const getCodeTypesService = async (): Promise<ICodeTypesResponse | Error> => {
  try {
    const { url } = apiEndpoints.CODE_TYPES;

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const validateCodeService = async (payload: IValidateCodePayload): Promise<IValidateCodeResponse | Error> => {
  try {
    const { url } = apiEndpoints.VALIDATE_CODE(payload.code_name);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const generateCodeService = async (payload: IGenerateCodePayload): Promise<IGenerateCodeResponse | Error> => {
  try {
    const { url } = apiEndpoints.GENERATE_CODE();

    return await apiCaller.post(url, payload);
  } catch (e) {
    throw e;
  }
};
