import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/bootstrap.css';
import { COUNTRY_CODE, DIAL_CODE } from '@common/constants/projectConfiguration';
import { CountryData } from 'react-phone-input-2';

import { StyledError, StyledLabel, StyledPhoneInput, StyledPhoneInputWrapper } from './style';

export interface IProps {
  onChange?: (country_code: string, contact_number: string) => void;
  onBlur?: () => void;
  bottomSpace?: number;
  label?: string;
  id: string;
  isError?: boolean;
  errorMessage?: string;
  country_code?: string;
  contact_number?: string;
  disabled?: boolean;
  topSpace?: number;
}

const BMPhoneInput = (props: IProps) => {
  const [phone, setPhone] = useState<string | undefined>(DIAL_CODE);
  const { bottomSpace, label, id, isError, errorMessage, country_code = '', contact_number = '', disabled, topSpace } = props;

  useEffect(() => {
    if(country_code && contact_number) {
      setPhone(`${country_code}${contact_number}`);
    }
  }, []);

  const handleInputChange = (numberValue: string, data: CountryData) => {
    setPhone(data.dialCode);
    if (data.dialCode && numberValue) {
      const contactNumberOnly = numberValue.substring(data.dialCode.length, numberValue.length);
      if(props.onChange) {
        props.onChange(data.dialCode, contactNumberOnly);
      }
    }
  };

  const handleBlur = () => {
    props.onBlur?.();
  };

  return (
    <StyledPhoneInputWrapper className='bm-phone-wrapper' topSpace={topSpace} bottomSpace={bottomSpace}>
      {label ? <StyledLabel htmlFor={id}>{label}</StyledLabel> : null}
      <StyledPhoneInput
        country={COUNTRY_CODE}
        enableSearch={true}
        value={phone}
        onChange={handleInputChange}
        enableClickOutside
        containerClass={`bm-country-code-picker-container ${isError ? 'error' : ''}`}
        inputClass={'bm-country-code-picker-input'}
        searchPlaceholder={'Search..'}
        countryCodeEditable={false}
        inputProps={{ id }}
        autoFormat={true}
        onBlur={handleBlur}
        disableDropdown={!!disabled}
        disabled={!!disabled}
      />
      {isError ? <StyledError>{`${errorMessage ? `*${errorMessage}` : ''}`}</StyledError> : null}
    </StyledPhoneInputWrapper>
  );
};

export default BMPhoneInput;
