import { PERMISSIONS, canView } from '@common/utils/appConfig';
import { ILoginResponse } from '@src/pages/authentication/store/types';

export interface I_SIDEBAR_DATA {
  path: string;
  label: string;
  hide?: boolean;
}

export const ROUTE_METADATA = {
  LOGIN: {
    path: '/'
  },
  SIGNUP: {
    path: '/signup'
  },
  AFFILIATE_PARTNERS: {
    path: '/affiliate-partners'
  },
  AFFILIATE_CODES: {
    path: '/affiliate-codes'
  },
  AFFILIATE_CODE_DETAIL: {
    path: '/affiliate-code-detail'
  },
  AFFILIATE_MEMBER_INVOICES: {
    path: '/affiliate-member-invoices'
  },
  DASHBOARD: {
    path: '/dashboard'
  },
  ACCOUNT_DETAIL: {
    path: '/account-detail'
  },
  ADD_NEW_AFFILIATE_USER: {
    path: '/add-affiliate-user'
  },
  AFFILIATE_DETAIL: {
    path: '/affiliate-detail'
  },
  BLANK: ''
};

export const SIDEBAR_LOGIN_MENU = (): I_SIDEBAR_DATA[] => [
  {
    path: ROUTE_METADATA.DASHBOARD.path,
    label: 'DASHBOARD'
  },
  {
    path: ROUTE_METADATA.AFFILIATE_CODES.path,
    label: 'AFFILIATE CODES'
  },
  {
    path: ROUTE_METADATA.AFFILIATE_PARTNERS.path,
    label: 'AFFILIATE PARTNERS',
    hide: !canView(PERMISSIONS.AFFILIATE_PARTNERS)
  },
  {
    path: ROUTE_METADATA.ACCOUNT_DETAIL.path,
    label: 'ACCOUNT SETTINGS',
    hide: !canView(PERMISSIONS.ACCOUNT_SETTINGS)
  },
  {
    path: 'https://bodymapp.co/partner-materials',
    label: 'MANUAL'
  }
];

export const SIDEBAR_SIGN_OUT_MENU = (): I_SIDEBAR_DATA[] => [
  {
    path: ROUTE_METADATA.LOGIN.path,
    label: 'LOGIN'
  },
  {
    path: ROUTE_METADATA.SIGNUP.path,
    label: 'CREATE ACCOUNT'
  },
  {
    path: 'https://bodymapp.co/partner-materials',
    label: 'MANUAL'
  }
];

export const getSidebarMenu = (userDetail?: ILoginResponse) => userDetail?.access_token ? SIDEBAR_LOGIN_MENU() : SIDEBAR_SIGN_OUT_MENU();
