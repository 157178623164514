import styled from 'styled-components';
import { colors } from '@theme';

export const StyledNotFoundContainer = styled.section`
  min-height: 100vh;
  width: 100%;
`;

export const StyledNotFoundText = styled.div`
  text-align: center;
  margin-top: 50px;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 0.14px;
  color: ${colors.persianBlue};
  font-family: bm-medium;
`;
