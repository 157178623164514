import { IIconProps } from './index';

export default (props: IIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 16}
    height={props?.height || 21}
    fill={props?.color || 'currentColor'}
    className={props.className || ''}
    viewBox="0 0 15.708 20.945"
  >
    <g id="box-arrow-down" transform="translate(0 0)">
      <path
        id="Path_794"
        data-name="Path 794"
        d="M3.963,13.09a.654.654,0,0,1-.654-.654V1.963a.654.654,0,0,1,.654-.654H15.744a.654.654,0,0,1,.654.654V12.435a.654.654,0,0,1-.654.654H13.126a.654.654,0,0,0,0,1.309h2.618a1.963,1.963,0,0,0,1.963-1.963V1.963A1.963,1.963,0,0,0,15.744,0H3.963A1.963,1.963,0,0,0,2,1.963V12.435A1.963,1.963,0,0,0,3.963,14.4H6.581a.654.654,0,0,0,0-1.309Z"
        transform="translate(-2 0)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_795"
        data-name="Path 795"
        d="M8.618,19.208a.655.655,0,0,0,.927,0l3.927-3.927a.655.655,0,1,0-.927-.927l-2.809,2.81V5.654a.654.654,0,0,0-1.309,0v11.51l-2.809-2.81a.655.655,0,1,0-.927.927Z"
        transform="translate(-1.228 1.545)"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

