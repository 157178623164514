import styled from 'styled-components';
import { colors } from '@theme';

export const StyledDatePickerWrapper = styled.div`
  width: fit-content;
  .react-datepicker__portal {
    align-items: flex-start;
  }
  .react-datepicker {
    top: 100px;
  }
  .react-datepicker__header__dropdown {
    margin-top: 15px;
    select {
      padding: 3px;
      margin: 0 5px;
      font-family: bm-medium;
    }
  }
  .react-datepicker__month {
    min-height: 370px;
    @media only screen and (max-width: 800px) {
      min-height: 320px;
    }
  }
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 3.5rem;
    line-height: 3.5rem;
    @media only screen and (max-width: 800px) {
      width: 2.5rem;
      line-height: 2.5rem;
    }
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
  .react-datepicker-wrapper {
    width: fit-content;
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
  .react-datepicker__input-container {
    width: fit-content;
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
  .bm-date-picker {
    box-sizing: border-box;
    width: 185px;
    height: 44px;
    background: ${colors.white} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px ${colors.shadowBlack};
    border-radius: 8px;
    padding: 0 10px 0 10px;

    text-align: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.05px;
    color: ${colors.cloudyGrey};
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
`;
