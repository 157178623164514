const namespace = 'DASHBOARD_';

export default {
  GET_REDEMPTION_LIST: `${namespace}GET_REDEMPTION_LIST`,
  SET_REDEMPTION_LIST: `${namespace}SET_REDEMPTION_LIST`,
  REDEEM_OFFER: `${namespace}REDEEM_OFFER`,
  CREATE_ACCOUNT: `${namespace}CREATE_ACCOUNT`,
  SHOW_REDEEM_OFFER: `${namespace}SHOW_REDEEM_OFFER`,
  GET_REDEEM_LINK: `${namespace}GET_REDEEM_LINK`,
  SET_REDEEM_LINK: `${namespace}SET_REDEEM_LINK`
};
