import styled from 'styled-components';

export const StyledInputWrapper = styled.div<{ bottomSpace?: number; topSpace?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: ${({ bottomSpace }) => (bottomSpace ? `${bottomSpace}px` : '0px')};
  padding-top: ${({ topSpace }) => (topSpace ? `${topSpace}px` : '0px')};
  position: relative;
`;

export const StyledTextInput = styled.input<{ disabled?: boolean; type?: string }>`
  height: 40px;
  outline: none;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 0 0 10px;
  padding-right: ${({ type }) => (type === 'password' ? '35px' : '10px')};
  box-sizing: border-box;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'unset')};
  &.error {
    border: 1.5px solid ${({ theme }) => theme.colors.warning};
  }
`;

export const StyledLabel = styled.label`
  margin-bottom: 13px;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.62px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledError = styled.span<{inputWidth: number}>`
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.62px;
  color: ${({ theme }) => theme.colors.warning};
  margin-top: 5px;
  max-width: ${({ inputWidth }) => `${inputWidth}px`};
`;

export const StyledInputBox = styled.div<{disabled: boolean}>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .eye-slash {
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    position: absolute;
    right: 10px;
    border: none;
    outline: none;
    height: auto;
    width: auto;
    background: none;
  }
`;
