import { IGetCodeDetailParams } from '@src/pages/affiliateCodes/store/types';
import { IGetPartnerDetailPayload } from '@src/pages/affiliatePartners/store/types';
import { getQueryString } from '@common/utils';
import { IGetListParams } from '@common/types';

export default {
  AUTHENTICATION: {
    LOGIN: {
      url: '/users/login'
    },
    USER_SIGNUP: {
      url: '/users/signup'
    },
    AFFILIATES_SIGNUP: {
      url: '/affiliates/signup'
    },
    FORGOT_PASS: {
      url: 'users/forgot-password'
    },
    RESET_PASS: {
      url: '/users/reset-password'
    }
  },
  CODE_TYPES: {
    url: '/codes/types'
  },
  VALIDATE_CODE: (code_name: string) => ({
    url: `/codes/exists/${code_name}`
  }),
  GENERATE_CODE: () => ({
    url: '/codes/create'
  }),
  AFFILIATE_CODE_BATCHES_LIST: (params: IGetListParams | void) => ({
    url: `/codes/list${getQueryString(params)}`
  }),
  AFFILIATE_CODES_LIST: (code_batch_id: number, params: IGetListParams | void) => ({
    url: `/codes/list/${code_batch_id}/codes${getQueryString(params)}`
  }),
  AFFILIATE_CODE_BATCH_DETAIL: (params: IGetCodeDetailParams) => {
    const { code_batch_id } = params || {};

    return {
      url: `/codes/batch/${code_batch_id}`
    };
  },
  SET_CODE_STATUS: () => ({
    url: '/codes/status'
  }),
  CODE_REDEMPTION_LIST: (params: IGetListParams | void) => ({
    url: `/members/list${getQueryString(params)}`
  }),
  EXPIRE_REDEMPTION: () => ({
    url: '/members/expire-redemption'
  }),
  REDEEM_OFFER: () => ({
    url: '/members/redeem-offer'
  }),
  GENERATE_REDEEM_LINK: () => ({
    url: '/codes/redeem-code-link'
  }),
  CREATE_ACCOUNT: () => ({
    url: '/members/create-account'
  }),
  GET_ACCOUNT_DETAIL: () => ({
    url: '/affiliates'
  }),
  UPDATE_ACCOUNT_DETAIL: () => ({
    url: '/affiliates'
  }),
  AFFILIATE_TEAM_LIST: (params: IGetListParams | void) => ({
    url: `/affiliates/team${getQueryString(params)}`
  }),
  AFFILIATE_TEAM_ADD_NEW_USER: () => ({
    url: '/affiliates/team/user'
  }),
  AFFILIATE_PARTNERS_LIST: (params: IGetListParams | void) => ({
    url: `/bm-admin/affiliates${getQueryString(params)}`
  }),
  AFFILIATE_MEMBER_INVOICES: (params: IGetListParams | void) => ({
    url: `/members/invoices${getQueryString(params)}`
  }),
  AFFILIATE_PARTNER_DETAIL: (params: IGetPartnerDetailPayload) => {
    const { affiliate_id } = params || {};

    return {
      url: `/bm-admin/affiliates/${affiliate_id}`
    };
  },
  SELECT_DESELECT_OFFER: () => ({
    url: '/bm-admin/affiliates/offers'
  }),
  CHANGE_ACCOUNT_STATUS: () => ({
    url: 'bm-admin/affiliates/status'
  }),
  SET_REDEMPTIONS_LIMIT: () => ({
    url: 'bm-admin/affiliates/redemptions-limit'
  }),
  UPDATE_MEMBER_STATUS: () => ({
    url: '/affiliates/team/user/status'
  }),
  GET_USER_ROLES: () => ({
    url: '/affiliates/team/roles'
  })
};
