import { StyledButton } from './styles';

export interface IProps extends React.HTMLProps<HTMLInputElement> {
  buttonText?: string;
  onClick: () => void;
  variant?: 'primary' | 'secondary' | 'transparent';
  disabled?: boolean;
  dataTestId?: string;
  children?: React.ReactNode;
}

const Button = (props: IProps) => {
  const { buttonText, onClick = () => undefined, variant = '', className = '', disabled, dataTestId, children } = props;

  return (
    <StyledButton
      disabled={disabled}
      className={`bm-button ${variant} ${className} ${disabled && 'disabled'}`}
      type="button"
      onClick={onClick}
      data-testid={dataTestId || 'bm-button'}
    >
      {children}
      {buttonText ? <span>{buttonText}</span> : null}
    </StyledButton>
  );
};

export default Button;
