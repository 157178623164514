import { SORT_DIRECTION } from '@src/common/types';
import { IAffiliateAccountState } from './types';

export default (): IAffiliateAccountState => ({
  accountDetail: null,
  team: {
    page_size: 0,
    page_number: 0,
    total_pages: 0,
    next_page: 0,
    sort_key: '',
    sort_direction: SORT_DIRECTION.ASC,
    sort_options: {
      sort_keys: [],
      sort_directions: []
    },
    page: []
  },
  userRoles: []
});
