import { actionCreator } from '@store/actionCreator';
import CONSTANTS from './constants';
import { IGenericPageListResponse, IGetListParams } from '@common/types';
import { IAffiliateMemberInvoice, IChangeStatusPayload, IGetPartnerDetailPayload, IOfferPayload, IPageListData, IPartnerDetailState, ISetRedemptionsLimitPayload } from './types';

export default {
  getAffiliatePartners: actionCreator<IGetListParams | void>(CONSTANTS.GET_AFFILIATE_PARTNERS),
  setAffiliatePartners: actionCreator<IGenericPageListResponse<IPageListData>>(CONSTANTS.SET_AFFILIATE_PARTNERS),
  getPartnerDetail: actionCreator<IGetPartnerDetailPayload>(CONSTANTS.GET_PARTNER_DETAIL),
  setPartnerDetail: actionCreator<IPartnerDetailState>(CONSTANTS.SET_PARTNER_DETAIL),
  selectDeselectOffer: actionCreator<IOfferPayload>(CONSTANTS.SELECT_DESELECT_OFFER),
  changeAccountStatus: actionCreator<IChangeStatusPayload>(CONSTANTS.CHANGE_ACCOUNT_STATUS),
  setRedemptionsLimit: actionCreator<ISetRedemptionsLimitPayload>(CONSTANTS.SET_REDEMPTIONS_LIMIT),
  getMemberInvoices: actionCreator<IGetListParams | void>(CONSTANTS.GET_MEMBER_INVOICES),
  setMemberInvoices: actionCreator<IGenericPageListResponse<IAffiliateMemberInvoice>>(CONSTANTS.SET_MEMBER_INVOICES)
};
