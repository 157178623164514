import apiEndpoints from '@src/common/constants/apiEndpoints';
import apiCaller from '@src/store/apiCaller';
import history from '@src/common/history';
import { IAxiosListResponse, IAxiosResponse, IGetListParams } from '@common/types';

import {
  IAffiliateMemberInvoice,
  IChangeStatusPayload,
  IGetPartnerDetailPayload,
  IOfferPayload,
  IPageListData,
  IPartnerDetailState,
  ISetRedemptionsLimitPayload
} from './types';

export const getPartnersListService = async (sagaPayload: IGetListParams): Promise<IAxiosListResponse<IPageListData> | Error> => {
  try {
    const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));
    let payload = {
      sort_key: queryParameters.sort_key,
      sort_direction: queryParameters.sort_direction,
      page_number: queryParameters.page_number,
      page_size: queryParameters.page_size
    } as IGetListParams;

    if(queryParameters.from && queryParameters.to) {
      payload.from = queryParameters.from;
      payload.to = queryParameters.to;
    }

    if(sagaPayload?.export) {
      payload = {
        export: sagaPayload.export
      };
    }

    const { url } = apiEndpoints.AFFILIATE_PARTNERS_LIST(payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const getPartnerDetailService = async (
  payload: IGetPartnerDetailPayload
): Promise<IAxiosResponse<IPartnerDetailState> | Error> => {
  try {
    const { url } = apiEndpoints.AFFILIATE_PARTNER_DETAIL(payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const setOfferService = async (
  payload: IOfferPayload
): Promise<IAxiosResponse<IPartnerDetailState> | Error> => {
  try {
    const { url } = apiEndpoints.SELECT_DESELECT_OFFER();

    return await apiCaller.put(url, payload);
  } catch (e) {
    throw e;
  }
};

export const changeAccountStatusService = async (
  payload: IChangeStatusPayload
): Promise<IAxiosResponse<IPartnerDetailState> | Error> => {
  try {
    const { url } = apiEndpoints.CHANGE_ACCOUNT_STATUS();

    return await apiCaller.put(url, payload);
  } catch (e) {
    throw e;
  }
};

export const setRedemptionsLimitService = async (
  payload: ISetRedemptionsLimitPayload
): Promise<IAxiosResponse<IPartnerDetailState> | Error> => {
  try {
    const { url } = apiEndpoints.SET_REDEMPTIONS_LIMIT();

    return await apiCaller.put(url, payload);
  } catch (e) {
    throw e;
  }
};

export const getMemberInvoicesService = async (sagaPayload: IGetListParams): Promise<IAxiosListResponse<IAffiliateMemberInvoice> | Error> => {
  try {
    const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));
    let payload = {
      sort_key: queryParameters.sort_key,
      sort_direction: queryParameters.sort_direction,
      page_number: queryParameters.page_number,
      page_size: queryParameters.page_size,
      affiliate_id: queryParameters.affiliate_id
    } as IGetListParams;

    if(queryParameters.from && queryParameters.to) {
      payload.from = queryParameters.from;
      payload.to = queryParameters.to;
    }

    if(sagaPayload?.export) {
      payload = {
        export: sagaPayload.export,
        affiliate_id: queryParameters.affiliate_id
      };
    }

    const { url } = apiEndpoints.AFFILIATE_MEMBER_INVOICES(payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};
