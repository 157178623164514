import { IRootState } from '@store/types';
import { createSelector } from 'reselect';

export const getCommonState = (state: IRootState) => state.common;

export const getCodeTypesWithOffer = createSelector([getCommonState], commonState => Array.isArray(commonState.codeTypes)
    ? commonState.codeTypes.map(item => ({
        label: item.code_type,
        value: item.code_type,
        offers: Array.isArray(item.offers)
          ? item.offers.map(offerItem => ({
              value: offerItem.offer_type,
              label: offerItem.offer_summary
            }))
          : null
      }))
    : null);
