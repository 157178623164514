import DatePicker from 'react-datepicker';
import { StyledDatePickerWrapper } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

export interface IProps {
  onSelectDate?: (date: Date) => void;
  id?: string;
  isDisabled?: boolean;
  value?: Date;
  dataTestId?: string;
  withPortal?: boolean;
  placeholder?: string;
  dateFormat?: string;
  wrapperClass?: string;
}

const BMDatePicker = (props: IProps) => {
  const {
    id = 'bm-date-picker',
    isDisabled,
    onSelectDate,
    value,
    dataTestId,
    withPortal = false,
    placeholder,
    dateFormat,
    wrapperClass
  } = props;

  const handleDateChange = (date: Date) => {
    onSelectDate?.(date);
  };

  return (
    <StyledDatePickerWrapper className={wrapperClass || ''}>
      <DatePicker
        className="bm-date-picker"
        closeOnScroll={false}
        selected={value}
        onChange={handleDateChange}
        placeholderText={placeholder || 'Select date'}
        id={id}
        disabled={!!isDisabled}
        data-testid={dataTestId}
        withPortal={withPortal}
        dateFormat={dateFormat || 'dd MMM yyyy'}
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        dropdownMode="select"
      />
    </StyledDatePickerWrapper>
  );
};

export default BMDatePicker;
