import React from 'react';
import Routes from './pages';
import ThemeProvider from './theme/themeProvider';
import { Provider } from 'react-redux';
import store from '@store/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default () => (
  <Provider store={store}>
    <ToastContainer />
    <ThemeProvider>
      <Routes />
    </ThemeProvider>
  </Provider>
);
