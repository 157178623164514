import { ROUTE_METADATA } from '@common/constants/routeConstants';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import CONSTANTS from '@store/constants';
import { generateCodeService, getCodeTypesService, validateCodeService } from '@store/services/common';
import commonActions from '@store/actions/common';
import {
  CUSTOM_CODE_STATUS,
  ICodeTypesResponse,
  IGenerateCodePayload,
  IGenerateCodeResponse,
  IValidateCodePayload,
  IValidateCodeResponse
} from '@store/types/common';
import { toast } from 'react-toastify';
import { logError } from '@store/utils';
import history from '@src/common/history';
import affiliateCodesActions from '@src/pages/affiliateCodes/store/actions';

export function* getCodeTypesSaga(): Generator {
  try {
    const response = (yield call(getCodeTypesService)) as ICodeTypesResponse;
    if (response.success) {
      yield put(commonActions.setCodeTypes(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* validateCodeSaga({ payload }: { type: string; payload: IValidateCodePayload }): Generator {
  try {
    const response = (yield call(validateCodeService, payload)) as IValidateCodeResponse;
    if (response && response.success && !response.payload.exists) {
      yield put(commonActions.setCustomCodeStatus(CUSTOM_CODE_STATUS.valid));
    } else {
      yield put(commonActions.setCustomCodeStatus(CUSTOM_CODE_STATUS.error));
    }
  } catch (error) {
    logError(error);
  }
}

export function* generateCodeSaga({ payload }: { type: string; payload: IGenerateCodePayload }): Generator {
  try {
    const response = (yield call(generateCodeService, payload)) as IGenerateCodeResponse;
    if (response && response.success && response.payload.code_batch_id) {
      toast('Affiliate partner code created!', {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: 'success'
      });
      if(history.location.pathname !== ROUTE_METADATA.AFFILIATE_CODES.path) {
        history.push(ROUTE_METADATA.AFFILIATE_CODES.path);
      }
      if(history.location.pathname === ROUTE_METADATA.AFFILIATE_CODES.path) {
        yield put(affiliateCodesActions.getAffiliateCodeBatches());
      }
      yield put(commonActions.generateCodeToggle(false));
    }
  } catch (error) {
    logError(error);
  }
}

function* watcherSaga(): Generator {
  yield takeLatest(CONSTANTS.GET_CODE_TYPES, getCodeTypesSaga);
  yield takeLatest(CONSTANTS.VALIDATE_CODE, validateCodeSaga);
  yield takeLatest(CONSTANTS.GENERATE_CODE, generateCodeSaga);
}

export default watcherSaga;
