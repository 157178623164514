import { SortDown, SortUp } from '@staticImages';
import { StyledTCell, StyledTableContainer, StyledTableHeadCell, StyledTableInnerContainer, StyledTableMainContainer } from './styles';
import { Button, Pagination, SelectDropDown, SwitchInput } from '@components/atoms';
import {  SORT_DIRECTION } from '@common/types';
import { GrayCircle, GreenCircle } from '@components/icons';
import { getAndSetQueryParams, replaceQueryParams } from '@common/utils';
import { CSVDownloadButton } from '@components/molecules';
import { colors } from '@src/theme';
import { useEffect } from 'react';
import history from '@src/common/history';

export interface ITableHeader {
  name: string;
  label: string;
  sortingEnabled?: boolean;
  // eslint-disable-next-line
  customRightNode?: any;
  className?: string;
  columnWidth?: string;
  isNoBackground?: boolean;
}

export interface ITableBody {
  label?: string | number;
  // eslint-disable-next-line
  customLabel?: any;
  className?: string;
  name: string;
  clickable?: boolean;
  value?: number | string;
  columnWidth?: string;
  isNoBackground?: boolean;
  icon?: string;
  customElement?: ICustomCellElementProps | null;
  // eslint-disable-next-line
  rowData?: any;
}

export interface IActionCallbackParams {
  export?: string;
}

export interface IProps {
  header: ITableHeader[];
  body: ITableBody[][];
  currentPage?: number;
  totalCount?: number;
  pageSize?: number;
  onSortClick?: (sort_key: string, sort_direction: SORT_DIRECTION) => void;
  onCellClick?: (value: ITableBody) => void;
  actionCallback?: (params?: IActionCallbackParams) => void;
  withDownloadButton?: boolean;
  isPageSizeDropdownNeeded?: boolean;
}

export enum CustomCellElementType {
  SWITCH = 'SWITCH',
  BUTTON = 'BUTTON',
}

export interface ICustomCellElementProps {
  elementType: CustomCellElementType;
  action: <T>(params?: T) => void;
  isChecked?: boolean;
  label?: string;
}

const Table = (props: IProps) => {
  const {
    header,
    body,
    onCellClick,
    currentPage,
    totalCount,
    pageSize = 0,
    actionCallback,
    withDownloadButton = true,
    isPageSizeDropdownNeeded = true
  } = props;

  const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));

  const getIcon = (icon?: string) => {
    switch (icon) {
      case 'GREEN_CIRCLE':
        return <GreenCircle />;
      case 'GRAY_CIRCLE':
        return <GrayCircle />;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!queryParameters?.page_size) {
      replaceQueryParams(getAndSetQueryParams({ page_size: 25 }));
    }
  }, []);

  const customCellElement = (customCellProps?: ICustomCellElementProps) => {
    switch (customCellProps?.elementType) {
      case CustomCellElementType.SWITCH:
        return (
        <SwitchInput
          label={''}
          handleToggle={
            async () => {
            await customCellProps.action();
            actionCallback?.();
          }
        }
          isChecked={customCellProps?.isChecked || false}
          onColor={colors.aquaBlue}
          handleDiameter={20}
          height={10}
          width={30}
          wrapperClass='aqua-switch'
        />
          );
      case CustomCellElementType.BUTTON:
        return (
          <Button
              variant="primary"
              buttonText={customCellProps.label}
              className="primary"
              onClick={async () => {
                await customCellProps.action();
                actionCallback?.();
                }
              }
          />
        );
      default:
        return null;
    }
  };

  const handleSortClick = (name: string, sortKey: string, sort_direction: SORT_DIRECTION) => {
    const direction =
      name === sortKey
        ? sort_direction === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC
        : SORT_DIRECTION.DESC;
    replaceQueryParams(getAndSetQueryParams({ sort_key: name, sort_direction: direction }));
    actionCallback?.();
  };

  const getSortDirectionImg = (name: string, sortKey: string, sortDirection: SORT_DIRECTION) => {
    if (name === sortKey) {
      return sortDirection === SORT_DIRECTION.ASC ? (
        <img src={SortUp} alt={'sort up'} />
      ) : (
        <img src={SortDown} alt={'sort down'} />
      );
    }

    return <img src={SortUp} alt={'sort up'} />;
  };

  const handleCellClick = (value: ITableBody) => {
    if (value.clickable) {
      onCellClick?.(value);
    }
  };

  const handlePaginationChange = (page_number: number) => {
    replaceQueryParams(getAndSetQueryParams({ page_number }));
    actionCallback?.();
  };

  const handleDownloadList = () => {
    actionCallback?.({ export: 'csv' });
  };

  const handlePageSize = (page_size: string) => {
    replaceQueryParams(getAndSetQueryParams({ page_size, page_number: 1 }));
    actionCallback?.();
  };

  const columnWidth = {};
  const currentPageNumber = queryParameters.page_number || 0;

  return (
    <StyledTableMainContainer id="table_main_container">
      {withDownloadButton && Array.isArray(body) && body.length ? <CSVDownloadButton onClick={handleDownloadList} /> : null}
      <StyledTableContainer className="bm_table_wrapper">
        <StyledTableInnerContainer id="table_inner_container">
          <table data-testid={'bm_table_component'}>
            <thead>
              <tr>
                {Array.isArray(header) &&
                  header.map((headerItem, index) => {
                    const sortKey = queryParameters.sort_key || '';
                    const sortDirection = (queryParameters.sort_direction || SORT_DIRECTION.ASC) as SORT_DIRECTION;
                    if (!columnWidth[headerItem.name]) {
                      columnWidth[headerItem.name] = headerItem.columnWidth;
                    }

                    return (
                      <StyledTableHeadCell
                        noBackground={headerItem.isNoBackground}
                        columnWidth={headerItem.columnWidth}
                        key={`table-header-${index}`}
                        className={headerItem.className}
                      >
                        <div className={'table-head-cell'}>
                          <div />
                          <div>{headerItem.label}</div>
                          {headerItem.sortingEnabled ? (
                            <div className={'image-wrapper'}>
                              <Button
                                variant={'transparent'}
                                onClick={() => {
                                  handleSortClick(headerItem.name, sortKey, sortDirection);
                                }}
                              >
                                {getSortDirectionImg(headerItem.name, sortKey, sortDirection)}
                              </Button>
                            </div>
                          ) : null}
                          {headerItem.customRightNode || null}
                          {!headerItem.customRightNode && !headerItem.sortingEnabled ? <div /> : null}
                        </div>
                      </StyledTableHeadCell>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(body) &&
                body.map((bodyItem, index) => (
                  <tr key={`body-main-item-${index}`}>
                    {Array.isArray(bodyItem) &&
                      bodyItem.map((bodyRowItem, bodyRowIndex) => (
                        <StyledTCell
                          noBackground={bodyRowItem.isNoBackground}
                          columnWidth={columnWidth[bodyRowItem.name]}
                          isClickable={bodyRowItem.clickable}
                          onClick={() => handleCellClick(bodyRowItem)}
                          key={`body-row-${bodyRowIndex}`}
                          className={`${bodyRowItem.className || ''} ${bodyRowItem.clickable ? 'clickable-td' : ''}`}
                        >
                          {bodyRowItem.customLabel || bodyRowItem.label}
                          {bodyRowItem.icon ? getIcon(bodyRowItem.icon) : null}
                          {bodyRowItem.customElement ? customCellElement(bodyRowItem.customElement) : null}
                        </StyledTCell>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </StyledTableInnerContainer>
        <div className={'pagination-wrapper'}>
          <div />
          <div>
            <Pagination
              className="pagination-bar"
              currentPage={Number(currentPageNumber) || currentPage || 0}
              totalCount={totalCount || 0}
              pageSize={pageSize || 0}
              onPageChange={handlePaginationChange}
            />
          </div>
          <div>
            {isPageSizeDropdownNeeded && pageSize >= 10 ? (
              <div className="page-size-wrapper">
                <span className="page-size">Show</span>
                <SelectDropDown
                  onSelect={handlePageSize}
                  name={'page_size'}
                  value={{ value: queryParameters.page_size, label: queryParameters.page_size }}
                  options={['10', '25', '50', '100'].map(size => ({ value: size, label: size }))}
                  isSearchable={false}
                  menuPosition="fixed"
                />
                <span className="page-size">per page</span>
              </div>
            ) : null}
          </div>
        </div>
      </StyledTableContainer>
    </StyledTableMainContainer>
  );
};

export default Table;
