export const colors =  {
  persianBlue: '#2238D8',
  persianGreen: '#06B49A',
  white: '#ffffff',
  transparent: 'transparent',
  smokeGrey: '#707070',
  shadowBlack: '#00000029',
  cloudyGrey: '#6B6B6B',
  warning: '#e41207',
  vistaWhite: '#f8f8f8',
  transparentBlack: '#0000003b',
  aquaBlue: '#00CDCD',
  brightBlue: '#005BF8',
  aquamarine_blue: '#49D8D8',
  modalBack: '#2e2e2ed1',
  silver: '#cccccc',
  lunarRock: '#c5c5c5',
  orange: '#FFA200',
  greenBlob: '#2bde00',
  black: '#000000',
  grey: 'grey'
};
