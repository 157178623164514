import { call, takeLatest } from '@redux-saga/core/effects';
import CONSTANTS from './constants';
import {
  ILoginPayload,
  ILoginResponse,
  ISignupPayload,
  ISignupResponse
} from './types';
import {
  requestLoginService,
  requestSignupService
} from './services';
import { logError } from '@store/utils';
import { handleLoginSignupRedirection } from '@common/utils';
import { IAxiosResponse } from '@common/types';
import { LOCAL_STORAGE, setLocalStorage } from '@common/utils/localStorageUtils';

export function* requestLoginSaga({ payload }: { type: string; payload: ILoginPayload }): Generator {
  try {
    const response = (yield call(requestLoginService, payload)) as IAxiosResponse<ILoginResponse>;
    if (response?.payload?.access_token) {
      setLocalStorage(LOCAL_STORAGE.USER_DETAIL, response.payload);
      handleLoginSignupRedirection(response.payload.next_steps);
    }
  } catch (error) {
    logError(error);
  }
}

export function* requestSignupSaga({ payload }: { type: string; payload: ISignupPayload }): Generator {
  try {
    const response = (yield call(requestSignupService, payload)) as IAxiosResponse<ISignupResponse>;
    if (response?.success && response?.payload) {
      setLocalStorage(LOCAL_STORAGE.USER_DETAIL, response.payload);
      handleLoginSignupRedirection(response.payload.next_steps, response.payload.display_message);
    }
  } catch (error) {
    logError(error);
  }
}

function* watcherSaga(): Generator {
  yield takeLatest(CONSTANTS.REQUEST_LOGIN, requestLoginSaga);
  yield takeLatest(CONSTANTS.REQUEST_SIGNUP, requestSignupSaga);
}

export default watcherSaga;
