const namespace = 'AFFILIATE_PARTNERS_';

export default {
  GET_AFFILIATE_PARTNERS: `${namespace}GET_AFFILIATE_PARTNERS`,
  SET_AFFILIATE_PARTNERS: `${namespace}SET_AFFILIATE_CODES`,
  GET_PARTNER_DETAIL: `${namespace}GET_PARTNER_DETAIL`,
  SET_PARTNER_DETAIL: `${namespace}SET_PARTNER_DETAIL`,
  SELECT_DESELECT_OFFER: `${namespace}SELECT_DESELECT_OFFER`,
  CHANGE_ACCOUNT_STATUS: `${namespace}CHANGE_ACCOUNT_STATUS`,
  GET_MEMBER_INVOICES: `${namespace}GET_MEMBER_INVOICES`,
  SET_MEMBER_INVOICES: `${namespace}SET_MEMBER_INVOICES`,
  SET_REDEMPTIONS_LIMIT: `${namespace}SET_REDEMPTIONS_LIMIT`
};
