import { colors } from '@theme';
import styled from 'styled-components';

export const StyledVideoWrapper = styled.div`
  background-color: ${colors.modalBack};
  width: 100vw;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBody = styled.div`
  width: 90%;
  height: 100%;
  iframe {
    width: 100%;
    min-height: 80vh;
    max-height: 80vh;
  }
`;

export const StyledHeaderActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  button {
    background: ${colors.white} !important;
    height: 30px !important;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close-icon {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
`;
