import { AnyAction, Reducer } from 'redux';
import withProduce from '@store/withProduce';
import commonState from '@store/states/common';
import constants from '@store/constants';
import { CUSTOM_CODE_STATUS, ICodeTypes, ICommonState } from '@store/types/common';

const reducers = {
  [constants.SHOW_LOADER]: (state: ICommonState) => {
    state.loader = true;
  },
  [constants.REMOVE_LOADER]: (state: ICommonState) => {
    state.loader = false;
  },
  [constants.SET_CODE_TYPES]: (state: ICommonState, payload: ICodeTypes[]) => {
    state.codeTypes = payload;
  },
  [constants.SET_CUSTOM_CODE_STATUS]: (state: ICommonState, payload: CUSTOM_CODE_STATUS) => {
    state.customCodeStatus = payload;
  },
  [constants.GENERATE_CODE_TOGGLE]: (state: ICommonState, payload: boolean) => {
    state.isOpenGenerateCode = payload;
  }
};

export default withProduce(commonState, reducers) as Reducer<ICommonState, AnyAction>;
