import { Header } from '@src/components/molecules';
import { StyledNotFoundContainer, StyledNotFoundText } from './styles';

const NotFound = () => (
    <StyledNotFoundContainer>
      <Header />
      <StyledNotFoundText>Page Not Found!</StyledNotFoundText>
    </StyledNotFoundContainer>
  );

export default NotFound;
