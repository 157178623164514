import apiEndpoints from '@src/common/constants/apiEndpoints';
import apiCaller from '@src/store/apiCaller';
import history from '@src/common/history';
import { IAxiosListResponse, IAxiosResponse, IGetListParams } from '@common/types';
import { ICreateAccountPayload, IExpireRedemptionPayload, IExpireRedemptionResponse, IGenerateLinkResponse, IPageListData, IRedeemOfferPayload } from './types';

export const getRedemptionListService = async (sagaPayload: IGetListParams): Promise<IAxiosListResponse<IPageListData> | Error> => {
  try {
    const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));
    let payload = {
      sort_key: queryParameters.sort_key,
      sort_direction: queryParameters.sort_direction,
      page_number: queryParameters.page_number,
      affiliate_id: queryParameters.affiliate_id,
      code: queryParameters.code,
      page_size: queryParameters.page_size
    } as IGetListParams;

    if(queryParameters.from && queryParameters.to) {
      payload.from = queryParameters.from;
      payload.to = queryParameters.to;
    }

    if(sagaPayload?.export) {
      payload = {
        export: sagaPayload.export,
        code: queryParameters.code
      };
    }

    const { url } = apiEndpoints.CODE_REDEMPTION_LIST(payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const expireRedemptionService = async (payload: IExpireRedemptionPayload): Promise<IAxiosResponse<IExpireRedemptionResponse> | Error> => {
  try {
    const { url } = apiEndpoints.EXPIRE_REDEMPTION();

    return await apiCaller.put(url, payload);
  } catch (e) {
    throw e;
  }
};

export const redeemOfferService = async (payload: IRedeemOfferPayload): Promise<IAxiosResponse<IExpireRedemptionResponse> | Error> => {
  try {
    const { url } = apiEndpoints.REDEEM_OFFER();

    return await apiCaller.post(url, payload);
  } catch (e) {
    throw e;
  }
};

export const generateRedeemLinkService = async (): Promise<IAxiosResponse<IGenerateLinkResponse> | Error> => {
  try {
    const { url } = apiEndpoints.GENERATE_REDEEM_LINK();

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const createAccountService = async (payload: ICreateAccountPayload): Promise<IAxiosResponse<IExpireRedemptionResponse> | Error> => {
  try {
    const { url } = apiEndpoints.CREATE_ACCOUNT();

    return await apiCaller.post(url, payload);
  } catch (e) {
    throw e;
  }
};

