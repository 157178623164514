import { ICreateAccountPayload, IPageListData, IRedeemOfferPayload } from './types';
import { actionCreator } from '@store/actionCreator';
import { IGenericPageListResponse, IGetListParams } from '@common/types';
import CONSTANTS from './constants';

export default {
  getRedemptionList: actionCreator<IGetListParams | void>(CONSTANTS.GET_REDEMPTION_LIST),
  setRedemptionList: actionCreator<IGenericPageListResponse<IPageListData>>(CONSTANTS.SET_REDEMPTION_LIST),
  redeemOffer: actionCreator<IRedeemOfferPayload>(CONSTANTS.REDEEM_OFFER),
  createAccount: actionCreator<ICreateAccountPayload>(CONSTANTS.CREATE_ACCOUNT),
  showRedeemOffer: actionCreator<boolean>(CONSTANTS.SHOW_REDEEM_OFFER),
  getRedeemLink: actionCreator(CONSTANTS.GET_REDEEM_LINK),
  setRedeemLink: actionCreator<string>(CONSTANTS.SET_REDEEM_LINK)
};
