import apiEndpoints from '@src/common/constants/apiEndpoints';
import apiCaller from '@src/store/apiCaller';
import history from '@src/common/history';
import {
  ICodeBatchesListData,
  ICodesListData,
  IGetCodeDetailParams,
  IGetCodeDetailState,
  ISetCodeStatusPayload
} from '@src/pages/affiliateCodes/store/types';
import { IAxiosListResponse, IAxiosResponse, IGetListParams } from '@common/types';

export const getAffiliateCodeBatchesService = async (sagaPayload: IGetListParams): Promise<IAxiosListResponse<ICodeBatchesListData> | Error> => {
  try {
    const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));
    let payload = {
      sort_key: queryParameters.sort_key,
      sort_direction: queryParameters.sort_direction,
      page_number: queryParameters.page_number,
      page_size: queryParameters.page_size
    } as IGetListParams;

    if(sagaPayload?.export) {
      payload = {
        export: sagaPayload?.export
      } as IGetListParams;
    }
    const { url } = apiEndpoints.AFFILIATE_CODE_BATCHES_LIST(payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const getAffiliateCodeBatchDetailService = async (
  payload: IGetCodeDetailParams
): Promise<IAxiosResponse<IGetCodeDetailState> | Error> => {
  try {
    const { url } = apiEndpoints.AFFILIATE_CODE_BATCH_DETAIL(payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const setCodeStatusService = async (
  payload: ISetCodeStatusPayload
): Promise<IAxiosResponse<IGetCodeDetailState> | Error> => {
  try {
    const { url } = apiEndpoints.SET_CODE_STATUS();

    return await apiCaller.put(url, payload);
  } catch (e) {
    throw e;
  }
};

export const getAffiliateCodesService = async (sagaPayload: IGetListParams): Promise<IAxiosListResponse<ICodesListData> | Error> => {
  try {
    const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));
    let payload = {
      sort_key: queryParameters.sort_key,
      sort_direction: queryParameters.sort_direction,
      code_batch_id: Number(queryParameters.code_batch_id || 0),
      page_number: queryParameters.page_number,
      page_size: queryParameters.page_size
    } as IGetListParams;

    if(sagaPayload?.export) {
      payload = {
        export: sagaPayload?.export,
        code_batch_id: Number(queryParameters.code_batch_id || 0)
      } as IGetListParams;
    }
    const { url } = apiEndpoints.AFFILIATE_CODES_LIST(Number(queryParameters.code_batch_id || 0), payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};
