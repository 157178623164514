import { IGenericPageListResponse, IGetListParams } from '@src/common/types';
import { actionCreator } from '@store/actionCreator';
import CONSTANTS from './constants';
import { IAccountDetailState, IAddNewUserPayload, ITeamPageData, IUpdateAccountPayload, IUpdateMemberStatusPayload, IUpdateUserRolePayload } from './types';
import { USER_ROLE_TYPES } from '@common/utils/appConfig';

export default {
  getAccountDetail: actionCreator<void>(CONSTANTS.GET_ACCOUNT_DETAIL),
  setAccountDetail: actionCreator<IAccountDetailState>(CONSTANTS.SET_ACCOUNT_DETAIL),
  updateAccountDetail: actionCreator<IUpdateAccountPayload>(CONSTANTS.UPDATE_ACCOUNT_DETAIL),
  getAffiliateTeam: actionCreator<IGetListParams | void>(CONSTANTS.GET_AFFILIATE_TEAM),
  setAffiliateTeam: actionCreator<IGenericPageListResponse<ITeamPageData>>(CONSTANTS.SET_AFFILIATE_TEAM),
  addNewUser:actionCreator<IAddNewUserPayload>(CONSTANTS.ADD_NEW_USER),
  updateMemberStatus: actionCreator<IUpdateMemberStatusPayload>(CONSTANTS.UPDATE_MEMBER_STATUS),
  updateTeamMemberStatusInList: actionCreator<IUpdateMemberStatusPayload>(CONSTANTS.UPDATE_TEAM_MEMBER_STATUS_IN_LIST),
  getUserRoles: actionCreator<void>(CONSTANTS.GET_USER_ROLES),
  setUserRoles: actionCreator<USER_ROLE_TYPES[]>(CONSTANTS.SET_USER_ROLES),
  updateUser: actionCreator<IUpdateUserRolePayload>(CONSTANTS.UPDATE_USER)
};
