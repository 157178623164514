import { IAffiliatePartnersState } from './types';
import { SORT_DIRECTION } from '@common/types';

export default (): IAffiliatePartnersState => ({
  partnersList: {
    page_size: 0,
    page_number: 0,
    total_pages: 0,
    next_page: 0,
    total: 0,
    sort_key: '',
    sort_direction: SORT_DIRECTION.ASC,
    sort_options: {
      sort_keys: [],
      sort_directions: []
    },
    page: []
  },
  memberInvoicesList: {
    page_size: 0,
    page_number: 0,
    total_pages: 0,
    next_page: 0,
    total: 0,
    sort_key: '',
    sort_direction: SORT_DIRECTION.DESC,
    sort_options: {
      sort_keys: [],
      sort_directions: []
    },
    page: []
  },
  partnerDetail: null
});
