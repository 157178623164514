import { RawAxiosRequestHeaders } from 'axios';
import { IApiCallerConfig } from './apiCaller';
import { commonActions } from './actions';
import store from '@store/index';
import { getUserDetail } from '@common/utils/localStorageUtils';

declare const __DEV__: boolean;

export default (config: IApiCallerConfig) => {
  const {
    withBaseUrl = false,
    headers,
    includeHeaders = true,
    skipHeaders = false,
    withCredentials = __DEV__,
    loader = true,
    showGenericError = true,
    ...restConfig
  } = config;
  if (skipHeaders) {
    return {
      ...restConfig,
      headers: {
        ...headers
      }
    };
  }
  const queryParameters = Object.fromEntries(new URLSearchParams(location.search));
  const access_token = getUserDetail()?.access_token;

  const getAccessToken = () => {
    if(queryParameters.token) {
      return `Bearer ${queryParameters.token}`;
    } else {
      return access_token ? `Bearer ${access_token}` : 'AUTH';
    }
  };

  const baseURL = withBaseUrl ? '' : process.env.REACT_APP_API_URL;
  if (!!includeHeaders && config.headers) {
    config.headers = {
      'Content-Type': 'application/json',
      authorization: getAccessToken(),
      ...(headers as RawAxiosRequestHeaders)
    };
  }

  if (loader) {
    store.dispatch(commonActions.showLoader());
  }

  const requestConfig: IApiCallerConfig = {
    withBaseUrl,
    loader,
    baseURL,
    headers: config.headers,
    showGenericError,
    ...restConfig
  };
  if (withCredentials) {
    requestConfig.withCredentials = false;
  }

  return requestConfig;
};
