import styled from 'styled-components';

export const StyledSidebar = styled.section`
  min-width: 350px;
  height: 100vh;
  border: 1px solid blue;
  position: fixed;
  top: 0%;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 5px 0px 10px ${({ theme }) => theme.colors.transparentBlack};
  border: 1px solid ${({ theme }) => theme.colors.vistaWhite};
  box-sizing: border-box;
  z-index: 9;
  @media only screen and (max-width: 800px) {
    min-width: 280px;
  }
  .navlink-wrapper {
    width: 100%;
    padding: 34px 25px;
    box-sizing: border-box;
    ul {
      display: flex;
      flex-direction: column;
      row-gap: 23px;
    }
    li {
      list-style: none;
    }
    a {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.72px;
      color: ${({ theme }) => theme.colors.cloudyGrey};
      text-decoration: none;
      &.active-link {
        font-family: bm-semibold;
        color: ${({ theme }) => theme.colors.aquamarine_blue};
      }
    }
  }
`;

export const StyledLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  padding-top: 21px;
`;

export const StyledImage = styled.img`
  width: 168px;
  height: 40px;
  margin-right: 26px;
  object-fit: contain;
`;
