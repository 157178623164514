import { useEffect, useState } from 'react';
import { BodyMapGroupImg, HamburgerMenu } from '@common/images';
import { Button } from '@src/components/atoms';
import { GenerateCode } from '@src/components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '@store/actions';
import { getCommonState } from '@store/selectors/common';
import { PERMISSIONS, canEdit } from '@common/utils/appConfig';

import { StyledHeader } from './styles';
import { Sidebar } from '../index';

export enum HEADER_RIGHT_OPTIONS {
  ADD_CODE = 'ADD_CODE',
}
export interface IProps {
  rightOptions?: HEADER_RIGHT_OPTIONS[];
  showHamburger?: boolean;
}

const Header = (props: IProps) => {
  const { rightOptions, showHamburger = true } = props;
  const [navbarOpen, setNavbarOpen] = useState(false);

  const dispatch = useDispatch();
  const { isOpenGenerateCode } = useSelector(getCommonState);

  useEffect(() => {
    if (isOpenGenerateCode || navbarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpenGenerateCode, navbarOpen]);

  const handleGenerateCodeToggle = () => {
    dispatch(commonActions.generateCodeToggle(!isOpenGenerateCode));
  };

  return (
    <>
      {navbarOpen ? <Sidebar onClose={setNavbarOpen} /> : null}
      <StyledHeader>
        <div className="header-left">
          {showHamburger ? (
            <img
              src={HamburgerMenu}
              onClick={() => setNavbarOpen(true)}
              alt={'header-menu'}
              className="header-menu-img"
            />
          ) : null}
        </div>
        <div className="header-middle">
          <img src={BodyMapGroupImg} className="header-logo-img" alt={'header-logo'} />
        </div>
        <div className="header-right">
          {canEdit(PERMISSIONS.ADD_CODES) && rightOptions?.includes(HEADER_RIGHT_OPTIONS.ADD_CODE) ? (
            <Button
              variant="primary"
              buttonText={'ADD CODE'}
              className="header-primary-btn"
              onClick={handleGenerateCodeToggle}
            />
          ) : null}
        </div>
      </StyledHeader>
      {isOpenGenerateCode ? <GenerateCode isOpen={isOpenGenerateCode} onClose={handleGenerateCodeToggle} /> : null}
    </>
  );
};

export default Header;
