import { INPUT_TYPE } from '@common/enums';

export type VALIDATION_TYPE = 'regex' | 'none';

export type IVALIDATION_ACTION = 'onChange' | 'onSubmit' | 'onBlur';

export enum SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED'
}

export interface IValidationProps {
  type: VALIDATION_TYPE;
  value: string | RegExp;
  message: string;
  mandatory?: boolean;
  valueTypeErrorMessage?: string;
}

export interface IInputs {
  label: string;
  name: string;
}

export interface IFormFieldProp {
  name: string;
  label?: string;
  type?: INPUT_TYPE;
  placeholder?: string;
  bottomSpace?: number;
  validation?: IValidationProps;
  topSpace?: number;
  inputs?: IInputs[];
}

export interface IErrorInputFieldProps {
  errorMessage?: string;
  name: string;
}

export interface IErrorInputFields {
  [key: string]: IErrorInputFieldProps;
}

export interface ISignupForm {
  basicDetail: (IFormFieldProp[] | IFormFieldProp)[];
  companyDetail: (IFormFieldProp[] | IFormFieldProp)[];
}

export interface IGetListParams {
  page_number?: string;
  page_size?: string;
  sort_key?: string;
  sort_direction?: SORT_DIRECTION;
  from?: number | string;
  to?: number | string;
  affiliate_id?: string;
  export?: 'csv';
  code?: string;
  code_batch_id?: number;
}

export interface ISortOptions {
  sort_keys: string[];
  sort_directions: SORT_DIRECTION[];
}

export interface IAxiosResponse<TPayload> {
  success: boolean;
  payload: TPayload;
}

export interface IGenericPageListResponse<TPageList> {
  page_size: number;
  page_number: number;
  total_pages: number;
  next_page: number;
  sort_key: string;
  sort_direction: SORT_DIRECTION;
  sort_options: ISortOptions;
  total?: number;
  page: TPageList[];
  extras?: {
    total_paying: number;
    total_redeemed: number;
    total_offers_redeemed: number;
    offers_redemption_limit?: number;
  };
}

export interface IAxiosListResponse<TPageList> {
  success: boolean;
  payload: IGenericPageListResponse<TPageList>;
}
