import { colors } from '@theme';
import styled from 'styled-components';

export const StyledWrapper = styled.label<{disabled?: boolean}>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};
`;

export const StyledCheckbox = styled.input<{
  checkedBgColor?: string;
  checkIconColor?: string;
  borderColor?: string;
  borderColorChecked?: string;
  disabled?: boolean;
}>`
  width: 22px;
  height: 22px;
  position: relative;
  border: 2px solid ${({ borderColor }) => borderColor || colors.persianBlue};
  border-radius: 2px;
  appearance: none;
  outline: 0;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 1000ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 10px;
    border-style: solid;
    border-color: ${({ checkIconColor }) => checkIconColor || colors.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    border: 2px solid ${({ borderColorChecked, borderColor }) => borderColorChecked || borderColor || colors.persianBlue};
    color: ${colors.white};
    background: ${({ checkedBgColor }) => checkedBgColor || colors.persianBlue};
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
`;

export const StyledLabel = styled.span<{ isRtlLabel?: boolean; spaceBetweenItem?: number; disabled?: boolean }>`
  font-size: 15px;
  line-height: 20px;
  font-family: bm-bold;
  letter-spacing: 0.05px;
  color: ${({ theme }) => theme.colors.cloudyGrey};
  margin-left: ${({ isRtlLabel, spaceBetweenItem }) =>
    isRtlLabel && spaceBetweenItem ? `${spaceBetweenItem}px` : 'opx'};
  margin-right: ${({ isRtlLabel, spaceBetweenItem }) =>
    !isRtlLabel && spaceBetweenItem ? `${spaceBetweenItem}px` : 'opx'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;
