import produce from 'immer';
import { AnyAction } from 'redux';

export default (initialState: () => object, reducers: object) =>
  produce((state = initialState(), { type, payload, meta = {} }: AnyAction) => {
    if (reducers[type]) {
      reducers[type](state, payload, meta);
    }

    return state;
  });
