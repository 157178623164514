import { call, put, takeLatest } from '@redux-saga/core/effects';
import CONSTANTS from './constants';
import {
  IAffiliateMemberInvoice,
  IChangeStatusPayload,
  IGetPartnerDetailPayload,
  IOfferPayload,
  IPageListData,
  IPartnerDetailState,
  ISetRedemptionsLimitPayload
} from './types';
import { IAxiosListResponse, IAxiosResponse, IGetListParams } from '@common/types';
import { changeAccountStatusService, getMemberInvoicesService, getPartnerDetailService, getPartnersListService, setOfferService, setRedemptionsLimitService } from './services';
import { logError } from '@store/utils';
import actions from './actions';

export function* getAffiliatePartnersSaga({ payload }: { payload: IGetListParams; type: string }): Generator {
  try {
    const response = (yield call(getPartnersListService, payload)) as IAxiosListResponse<IPageListData>;
    if (response && response.success && response.payload) {
      yield put(actions.setAffiliatePartners(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* getPartnerDetailSaga({ payload }: { payload: IGetPartnerDetailPayload; type: string }): Generator {
  try {
    const response = (yield call(getPartnerDetailService, payload)) as IAxiosResponse<IPartnerDetailState>;
    if (response && response.success && response.payload) {
      yield put(actions.setPartnerDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* setOfferSaga({ payload }: { payload: IOfferPayload; type: string }): Generator {
  try {
    const response = (yield call(setOfferService, payload)) as IAxiosResponse<IPartnerDetailState>;
    if (response && response.success && response.payload) {
      yield put(actions.setPartnerDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* changeAccountStatusSaga({ payload }: { payload: IChangeStatusPayload; type: string }): Generator {
  try {
    const response = (yield call(changeAccountStatusService, payload)) as IAxiosResponse<IPartnerDetailState>;
    if (response && response.success && response.payload) {
      yield put(actions.setPartnerDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* setRedemptionsLimitSaga({ payload }: { payload: ISetRedemptionsLimitPayload; type: string }): Generator {
  try {
    const response = (yield call(setRedemptionsLimitService, payload)) as IAxiosResponse<IPartnerDetailState>;
    if (response && response.success && response.payload) {
      yield put(actions.setPartnerDetail(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* getAffiliateMemberInvoiceSaga({ payload }: { payload: IGetListParams; type: string }): Generator {
  try {
    const response = (yield call(getMemberInvoicesService, payload)) as IAxiosListResponse<IAffiliateMemberInvoice>;
    if (response && response.success && response.payload) {
      yield put(actions.setMemberInvoices(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

function* watcherSaga(): Generator {
  yield takeLatest(CONSTANTS.GET_AFFILIATE_PARTNERS, getAffiliatePartnersSaga);
  yield takeLatest(CONSTANTS.GET_PARTNER_DETAIL, getPartnerDetailSaga);
  yield takeLatest(CONSTANTS.SELECT_DESELECT_OFFER, setOfferSaga);
  yield takeLatest(CONSTANTS.CHANGE_ACCOUNT_STATUS, changeAccountStatusSaga);
  yield takeLatest(CONSTANTS.GET_MEMBER_INVOICES, getAffiliateMemberInvoiceSaga);
  yield takeLatest(CONSTANTS.SET_REDEMPTIONS_LIMIT, setRedemptionsLimitSaga);
}

export default watcherSaga;
