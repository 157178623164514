import { useEffect, useRef } from 'react';
import { SidebarLogo } from '@common/images';
import { useLocation } from 'react-router-dom';
import { getSidebarMenu } from '@constants/routeConstants';
import { BMLink } from '@components/atoms';
import { LOCAL_STORAGE, getUserDetail, removeLocalStorage } from '@common/utils/localStorageUtils';
import { ILoginResponse } from '@src/pages/authentication/store/types';

import { StyledLogo, StyledSidebar, StyledImage as StyledSidebarImage } from './styles';

interface ISidebar {
  onClose: (isOpen: boolean) => void;
}

const Sidebar = (props: ISidebar) => {
  const location = useLocation();
  const mainContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // eslint-disable-next-line
    const handleClickOutside = (event: any) => {
      if (mainContainerRef.current && !mainContainerRef.current?.contains(event.target as Node)) {
        props?.onClose?.(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.addEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleLogout = () => {
    props.onClose(false);
    removeLocalStorage(LOCAL_STORAGE.USER_DETAIL);
  };

  const userDetail = getUserDetail();

  return (
    <StyledSidebar ref={mainContainerRef}>
      <StyledLogo>
        <StyledSidebarImage src={SidebarLogo}></StyledSidebarImage>
      </StyledLogo>
      <div className="navlink-wrapper">
        <ul>
          {getSidebarMenu(userDetail as ILoginResponse).map((item, index) => (
            !item.hide && <li key={index}>
              <BMLink
                onClick={() => props.onClose(false)}
                className={item.path === location?.pathname ? 'active-link' : ''}
                to={item.path}
              >
                {item.label}
              </BMLink>
            </li>
          ))}
          {userDetail ? <li>
            <BMLink onClick={handleLogout} to={'/'}>
              {'LOGOUT'}
            </BMLink>
          </li> : null}
        </ul>
      </div>
    </StyledSidebar>
  );
};

export default Sidebar;
