import DatePicker from 'react-datepicker';
import { StyledDatePickerWrapper } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

export interface IDateRangePicker {
  onSelectDate?: (date: [Date | null, Date | null]) => void;
  id?: string;
  isDisabled?: boolean;
  dateRange: [Date | null, Date | null];
  dataTestId?: string;
  withPortal?: boolean;
  placeholder?: string;
  onCalendarClose?: () => void;
}

const DateRangePicker = (props: IDateRangePicker) => {
  const {
    id = 'bm-date-picker',
    isDisabled,
    onSelectDate,
    dateRange,
    dataTestId,
    withPortal = false,
    placeholder,
    onCalendarClose = () => undefined
  } = props;

  const handleDateChange = (date: [Date | null, Date | null]) => {
    onSelectDate?.(date);
  };

  const [startDate, endDate] = dateRange;
  const startDateString = startDate?.toLocaleDateString() || '';
  const endDateString = endDate?.toLocaleDateString() || '';

  return (
    <StyledDatePickerWrapper data-testid={dataTestId}>
      <DatePicker
        selectsRange={true}
        className="bm-date-picker"
        closeOnScroll={false}
        onChange={handleDateChange}
        placeholderText={placeholder || 'Select date'}
        id={id}
        disabled={!!isDisabled}
        withPortal={withPortal}
        startDate={startDate}
        endDate={endDate}
        onCalendarClose={onCalendarClose}
        dateFormat='dd MMM yyyy'
        value={(startDate && endDate) ? `${startDateString} - ${endDateString}` : 'dd/mm/yy - dd/mm/yy'}
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        dropdownMode="select"
      />
    </StyledDatePickerWrapper>
  );
};

export default DateRangePicker;
