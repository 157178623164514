import { call, put, takeLatest } from '@redux-saga/core/effects';
import CONSTANTS from './constants';
import { ICreateAccountPayload, IExpireRedemptionResponse, IGenerateLinkResponse, IPageListData, IRedeemOfferPayload } from './types';
import { createAccountService, generateRedeemLinkService, getRedemptionListService, redeemOfferService } from './services';
import { logError } from '@store/utils';
import { IAxiosListResponse, IAxiosResponse, IGetListParams } from '@common/types';
import actions from './actions';
import { toast } from 'react-toastify';
import history from '@src/common/history';
import { ROUTE_METADATA } from '@src/common/constants/routeConstants';

export function* getRedemptionListSaga({ payload }: { payload: IGetListParams; type: string }): Generator {
  try {
    const response = (yield call(getRedemptionListService, payload)) as IAxiosListResponse<IPageListData>;
    if (response && response.success && response.payload) {
      yield put(actions.setRedemptionList(response.payload));
    }
  } catch (error) {
    logError(error);
  }
}

export function* redeemOfferSaga({ payload }: { payload: IRedeemOfferPayload; type: string }): Generator {
  try {
    const response = (yield call(redeemOfferService, payload)) as IAxiosResponse<IExpireRedemptionResponse>;
    if (response && response.success && response.payload.display_message) {
      toast('Offer Redeemed!', {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: 'success'
      });
      history.push(ROUTE_METADATA.DASHBOARD.path);
      yield put(actions.showRedeemOffer(false));
      yield put(actions.getRedemptionList());
    }
  } catch (error) {
    logError(error);
  }
}

export function* createAccountSaga({ payload }: { payload: ICreateAccountPayload; type: string }): Generator {
  try {
    const response = (yield call(createAccountService, payload)) as IAxiosResponse<IExpireRedemptionResponse>;
    if (response && response.success && response.payload.display_message) {
      toast('Account Created!', {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: 'success'
      });
      history.push(ROUTE_METADATA.DASHBOARD.path);
      yield put(actions.showRedeemOffer(false));
      yield put(actions.getRedemptionList());
    }
  } catch (error) {
    logError(error);
  }
}

export function* getRedemptionLinkSaga(): Generator {
  try {
    const response = (yield call(generateRedeemLinkService)) as IAxiosResponse<IGenerateLinkResponse>;
    if (response && response.success && response.payload) {

      toast('Link Generated!', {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: 'success'
      });
      yield put(actions.setRedeemLink(response.payload.link));

    }
  } catch (error) {
    logError(error);
  }
}

function* watcherSaga(): Generator {
  yield takeLatest(CONSTANTS.GET_REDEMPTION_LIST, getRedemptionListSaga);
  yield takeLatest(CONSTANTS.REDEEM_OFFER, redeemOfferSaga);
  yield takeLatest(CONSTANTS.CREATE_ACCOUNT, createAccountSaga);
  yield takeLatest(CONSTANTS.GET_REDEEM_LINK, getRedemptionLinkSaga);
}

export default watcherSaga;
