import { AnyAction, Reducer } from 'redux';
import { IAuthenticationState } from './types';
import CONSTANTS from './constants';
import withProduce from '@src/store/withProduce';
import initialState from './state';

const reducer = {
  [CONSTANTS.REQUEST_LOGIN]: (state: IAuthenticationState) => {
    state.isLoggedIn = true;
  }
};

export default withProduce(initialState, reducer) as Reducer<IAuthenticationState, AnyAction>;
