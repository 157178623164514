import styled from 'styled-components';

export const StyledHeader = styled.section`
  width: 100%;
  min-width: 100vw;
  min-height: 88px;
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${({ theme: { colors } }) =>
    `transparent linear-gradient(270deg, ${colors.aquaBlue} 0%, ${colors.brightBlue} 100%) 0% 0% no-repeat padding-box`};
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
  .header-left {
    padding-top: 23px;
    padding-left: 24px;
    .header-menu-img {
      width: 28px;
      height: 23px;
      cursor: pointer;
    }
  }
  .header-middle {
    @media only screen and (max-width: 700px) {
      display: none;
    }
    padding-top: 17px;

    .header-logo-img {
      object-fit: contain;
      width: 213px;
      height: 35px;
    }
  }
  .header-right {
    padding-right: 18px;
    padding-top: 16px;
    .header-primary-btn {
      background-color: ${({ theme }) => theme.colors.white};
      width: 143px;
      height: 38px;
      text-align: center;
      letter-spacing: 0.05px;
      font-size: 15px;
      line-height: 20px;
      font-weight: bold;
      box-shadow: 0px 10px 10px ${({ theme }) => theme.colors.transparentBlack};
      border-radius: 31px;
      color: ${({ theme }) => theme.colors.aquaBlue};
    }
  }
`;
