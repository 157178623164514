import { createGlobalStyle } from 'styled-components';

import PoppinsRegular from './Poppins-Regular.ttf';
import PoppinsBold from './Poppins-Bold.ttf';
import PoppinsMedium from './Poppins-Medium.ttf';
import PoppinsItalic from './Poppins-Italic.ttf';
import PoppinsSemiBold from './Poppins-SemiBold.ttf';

const FontStyles = createGlobalStyle`
@font-face {
  font-family: 'bm-regular';
  src: url(${PoppinsRegular}) format('woff2');
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'bm-medium';
  src: url(${PoppinsMedium}) format('woff2');
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'bm-bold';
  src: url(${PoppinsBold}) format('woff2');
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'bm-semibold';
  src: url(${PoppinsSemiBold}) format('woff2');
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'bm-italic';
  src: url(${PoppinsItalic}) format('woff2');
  font-style: italic;
  font-variant: normal;
}
`;

export default FontStyles;
