import { formatDate } from '@src/common/utils';
import { IRootState } from '@store/types/index';
import { CustomCellElementType, ICustomCellElementProps } from '@src/components/molecules/Table';
import { updateMemberStatusService } from './services';
import { APPROVAL_STATUS } from '@src/common/types';
import { PERMISSIONS, canEdit } from '@src/common/utils/appConfig';

export const getAffiliateAccount = (state: IRootState) => state.affiliateAccount;

export const getUserRolesSelector = (state: IRootState) => state.affiliateAccount.userRoles;

export const getAffiliateTeamTable = (state: IRootState) => {
  const sortKeys = state.affiliateAccount.team?.sort_options?.sort_keys || [];

  return {
    header: [
      {
        label: 'EMAIL',
        name: 'email',
        sortingEnabled: sortKeys.includes('email'),
        columnWidth: '200px'
      },
      {
        label: 'ROLES',
        name: 'roles',
        sortingEnabled: sortKeys.includes('roles'),
        columnWidth: '200px'
      },
      {
        label: 'ADDED',
        name: 'created_at',
        sortingEnabled: sortKeys.includes('created_at'),
        columnWidth: '180px'
      },
      {
        label: 'STATUS',
        name: 'status',
        sortingEnabled: sortKeys.includes('status'),
        columnWidth: '180px'
      },
      {
        label: '',
        name: 'status_icon',
        sortingEnabled: false,
        isNoBackground: true
      }
    ],
    body: state?.affiliateAccount?.team?.page?.map(item => [
      { name: 'email', label: item.email },
      { name: 'roles', label: item.roles.join(', ') || 'N/A', clickable: canEdit(PERMISSIONS.UPDATE_USER_ROLE) ? true : false, rowData: item },
      { name: 'created_at', label: formatDate(item.created_at) || 'N/A' },
      { name: 'status', label: item.status || 'N/A' },
      {
        name: 'approve_user',
        isNoBackground: true,
        customElement: canEdit(PERMISSIONS.BLOCK_UNBLOCK_USER) ? (
          {
          elementType: CustomCellElementType.SWITCH,
          action: () => updateMemberStatusService(
            {
              user_id: item.id,
              status: item.status === APPROVAL_STATUS.APPROVED ?
              APPROVAL_STATUS.BLOCKED :
              APPROVAL_STATUS.APPROVED
            }),
          isChecked: item.status === APPROVAL_STATUS.APPROVED
        }
        ) as ICustomCellElementProps : null
      }
    ])
  };
};
