// eslint-disable-next-line
export const logError = (error: any) => {
  // eslint-disable-next-line no-console
  console.log('==logError==', error?.data || error);
};

// eslint-disable-next-line
export const downloadCSV = (data: any) => {
  try {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'affiliate_data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    logError(error);
  }
};
