import { Reducer, Store, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware, { END, SagaMiddleware } from 'redux-saga';
import rootReducer from './reducers/index';
import { IInjectableReducer, IRootState } from './types';
import initialStates from './states/index';
import rootSaga from './sagas';

declare const __DEV__: boolean;

export type IWindow = Window;

export type RootState = IRootState;
export interface IStore extends Store<IRootState> {
  asyncReducers: { [key: string]: IInjectableReducer };
  runSaga: SagaMiddleware<{}>['run'];
  colse(): void;
  injectReducer(key: string, asyncReducer: IInjectableReducer): void;
  injectSaga(key: string, saga: () => Generator): void;
}

function createSagaInjector(runSaga: SagaMiddleware<{}>['run']): (key: string, saga: () => Generator) => void {
  // keep track of injected saga
  const injectedSagas = new Map();
  const isInjected = (key: string) => injectedSagas.has(key);

  return (key: string, saga: () => Generator): void => {
    // don't run saga if it is already injected
    if (isInjected(key)) {
      return;
    }

    // run saga
    const task = runSaga(saga);

    // save the task to cancel it in the future
    injectedSagas.set(key, task);
  };
}

function createReducer(
  asyncReducer:
    | {
        [key: string]: IInjectableReducer;
      }
    | typeof rootReducer = {}
): Reducer<IRootState> {
  return combineReducers<IRootState>({
    ...rootReducer,
    ...asyncReducer
  });
}

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  __DEV__ &&
  // eslint-disable-next-line @typescript-eslint/dot-notation
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose)
    ? // eslint-disable-next-line @typescript-eslint/dot-notation
      (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose)
    : compose;

export const configureStore = (initialState: IRootState = {} as IRootState) => {
  const sagaMiddleware: SagaMiddleware<object> = createSagaMiddleware();
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
  const store = createStore(createReducer(), initialState, enhancer) as IStore;

  store.asyncReducers = {};

  // function to add the async reducer, and create a new combined reducer
  store.injectReducer = (key: string, asyncReducer: IInjectableReducer): void => {
    if (!store.asyncReducers[key]) {
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(createReducer(store.asyncReducers));
    }
  };

  sagaMiddleware.run(rootSaga);

  // add function to run saga
  store.injectSaga = createSagaInjector(sagaMiddleware.run);

  store.colse = () => {
    store.dispatch(END);
  };

  return store;
};

let store: IStore;

try {
  store = configureStore(initialStates() as IRootState);
} catch (__e) {
  store = configureStore();
}

export default store;
