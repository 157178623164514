import { Link } from 'react-router-dom';

export interface IProps {
  to: string;
  onClick?: () => void;
  className?: string;
  target?: string;
  dataTestId?: string;
  // eslint-disable-next-line
  children?: any;
}

const BMLink = (props: IProps) => {
  const { to, onClick = () => undefined, className = 'bm-link', target, dataTestId = 'bm-link', children } = props;

  return /^https?:\/\//.test(to) ? (
    <a
      className={className}
      data-testid={dataTestId}
      href={to}
      target={`${target || '_blank'}`}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link data-testid={dataTestId} to={to} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};

export default BMLink;
