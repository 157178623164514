import { Button } from '@components/atoms';
import { DownloadIcon } from '@components/icons';
import { StyledCSVButton } from './styles';

export interface IProps {
  onClick: () => void;
}

const CSVDownloadButton = (props: IProps) => {
  const { onClick } = props;

  return (
    <StyledCSVButton className='csv-button-wrapper'>
      <Button className='download-button' variant={'transparent'} onClick={onClick}>
        <span>DOWNLOAD LIST</span>
        <DownloadIcon />
      </Button>
    </StyledCSVButton>
  );
};

export default CSVDownloadButton;
