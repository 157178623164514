import apiEndpoints from '@src/common/constants/apiEndpoints';
import { IAxiosResponse, IGenericPageListResponse, IGetListParams } from '@src/common/types';
import apiCaller from '@src/store/apiCaller';
import {IAccountDetailState, IAddNewUserPayload, ITeamPageData, IUpdateAccountPayload, IUpdateMemberStatusPayload, IUpdateUserRolePayload } from './types';
import history from '@src/common/history';
import { USER_ROLE_TYPES } from '@common/utils/appConfig';

export const getAccountDetailService = async (): Promise<IAxiosResponse<IAccountDetailState> | Error> => {
  try {
    const { url } = apiEndpoints.GET_ACCOUNT_DETAIL();

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const updateAccountDetailService = async (payload: IUpdateAccountPayload): Promise<IAxiosResponse<IUpdateAccountPayload> | Error> => {
  try {
    const { url } = apiEndpoints.UPDATE_ACCOUNT_DETAIL();

    return await apiCaller.put(url, payload);
  } catch (e) {
    throw e;
  }
};

export const getAffiliateTeamService = async (sagaPayload: IGetListParams): Promise<IGenericPageListResponse<ITeamPageData> | Error> => {
  try {
    const queryParameters = Object.fromEntries(new URLSearchParams(history.location.search));
    let payload = {
      sort_key: queryParameters.sort_key,
      sort_direction: queryParameters.sort_direction,
      page_number: queryParameters.page_number,
      page_size: queryParameters.page_size,
      affiliate_id: queryParameters.affiliate_id
    } as IGetListParams;

    if(sagaPayload?.export) {
      payload = {
        export: sagaPayload?.export,
        affiliate_id: queryParameters.affiliate_id
      } as IGetListParams;
    }
    const { url } = apiEndpoints.AFFILIATE_TEAM_LIST(payload);

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const addNewUserService = async (payload: IAddNewUserPayload): Promise<IAxiosResponse<IAddNewUserPayload> | Error> => {
  try {
    const { url } = apiEndpoints.AFFILIATE_TEAM_ADD_NEW_USER();

    return await apiCaller.post(url, payload);
  } catch (e) {
    throw e;
  }
};

export const updateMemberStatusService = async (payload: IUpdateMemberStatusPayload): Promise<IAxiosResponse<null> | Error> => {
  try {
    const { url } = apiEndpoints.UPDATE_MEMBER_STATUS();

    return await apiCaller.post(url, payload);
  } catch (e) {
    throw e;
  }
};

export const getUserRolesService = async (): Promise<IAxiosResponse<USER_ROLE_TYPES[]> | Error> => {
  try {
    const { url } = apiEndpoints.GET_USER_ROLES();

    return await apiCaller.get(url);
  } catch (e) {
    throw e;
  }
};

export const updateUserService = async (payload: IUpdateUserRolePayload): Promise<IAxiosResponse<IUpdateUserRolePayload> | Error> => {
  try {
    const { url } = apiEndpoints.AFFILIATE_TEAM_ADD_NEW_USER();

    return await apiCaller.put(url, payload);
  } catch (e) {
    throw e;
  }
};
