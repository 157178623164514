import { IFormData } from '@src/pages/authentication/signup/types';
import { IErrorInputFields, IFormFieldProp, IVALIDATION_ACTION, IValidationProps } from '@common/types';
import { INPUT_TYPE } from '@common/enums';
export interface ISignupValidationParams {
  formData?: IFormData;
  action: IVALIDATION_ACTION;
  key?: string;
  errorFields?: IErrorInputFields | null;
  value?: string | number;
  type?: INPUT_TYPE;
  validation?: IValidationProps;
  formFields?: (IFormFieldProp[] | IFormFieldProp)[];
}

export interface IFormError {
  isError: boolean;
  errorFields?: IErrorInputFields | null;
}

export const validateSignup = (params: ISignupValidationParams): IFormError => {
  const { formFields } = params;
  if (params.action === 'onSubmit' && formFields) {
    const errorFields: IErrorInputFields = params.errorFields || {};
    const fieldsToCheck = formFields.flat(Infinity) as IFormFieldProp[];
    for (const item of fieldsToCheck) {
      if (item.validation?.mandatory && !params.formData?.[item.name]) {
        errorFields[item.name] = { errorMessage: item.validation?.message, name: item.name };
      } else {
        delete errorFields[item.name];
      }
    }

    const errorFieldsLength = Object.keys(errorFields).length;

    return { isError: !!errorFieldsLength, errorFields: errorFieldsLength ? errorFields : null };
  } else if ((params.action === 'onChange' || params.action === 'onBlur') && params.key) {
    const errorFields: IErrorInputFields = params.errorFields || {};
    let isErrorFoundOnChange = false;
    if (params.value) {
      const value = params.value.toString();
      if (params.validation?.type === 'regex' && params.validation?.value && !value.match(params.validation?.value)) {
        isErrorFoundOnChange = true;
        errorFields[params.key] = { errorMessage: params.validation.valueTypeErrorMessage, name: params.key };
      } else {
        isErrorFoundOnChange = false;
        delete errorFields[params.key];
      }
    } else if (params.validation?.mandatory && !params.value) {
      isErrorFoundOnChange = true;
      errorFields[params.key] = { errorMessage: params.validation.message, name: params.key };
    } else {
      isErrorFoundOnChange = false;
      delete errorFields[params.key];
    }

    return { isError: isErrorFoundOnChange, errorFields };
  }

  return { isError: false };
};
