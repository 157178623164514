import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const StyledPhoneInput = styled(PhoneInput)`
  &.bm-country-code-picker-container {
    width: 100%;
  }
  &.error {
    input {
      border: 1.5px solid ${({ theme }) => theme.colors.warning};
    }
  }
  .bm-country-code-picker-input {
    width: 100% !important;
    height: 37px;
    border-radius: 5px;
  }
`;

export const StyledPhoneInputWrapper = styled.div<{ bottomSpace?: number; topSpace?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ bottomSpace }) => (bottomSpace ? `${bottomSpace}px` : '0px')};
  margin-top: ${({ topSpace }) => (topSpace ? `${topSpace}px` : '0px')};
  width: 100%;
`;

export const StyledLabel = styled.label`
  margin-bottom: 13px;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.62px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledError = styled.span`
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.62px;
  color: ${({ theme }) => theme.colors.warning};
  margin-top: 5px;
`;
